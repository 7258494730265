import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConfigService } from '../../../shared/services/config.service';
import { AuthModel } from '../../models/auth//auth.model';
import { environment } from '../../../../environments/environment';
import { UserModel } from '../../models/auth/user.model';
import { AdminModel } from '../../models/auth/admin.model';
import { Router } from '@angular/router';
import { NuevoUsuario } from '../../models/auth/nuevo-usuario.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public muestraLogin: boolean = !(
    this.configService.config.sesion.invitado.active &&
    this.configService.config.sesion.invitado.ingreso_sin_login
  );
  private baseUrl = this.configService.config.servidor?.api_server;
  private urlCart = this.configService.config.servidor.api_cart;
  private accessToken: string = environment.AccessToken;
  private _usuario!: UserModel;
  private authLocalStorageData = 'data';
  public showLoader: boolean = false;

  get usuario(): UserModel {
    this._usuario = this.getAuthFromLocalStorage();
    return { ...this._usuario };
  }

  set usuario(user: UserModel) {}

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private router: Router
  ) {}

  // SC CATWEB-332 30/08/2021 Funcion
  login2(usuario: string, pass: string) {
    const url = `${this.baseUrl}/Loginv2`;
    // console.log(url);
    const body = { usuario: usuario, pass: pass };
    let params = {};
    {Object.assign(params, {Access_Token: this.accessToken, Plataforma: 2});}
    // const params = new HttpParams().set('Access_Token', this.accessToken);
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json; charset=utf-8');
    // headers.set('Access-Control-Request-Method', 'POST' );
    return this.http.post<AuthModel>(url, body, { params: params }).pipe(
      map((resp) => {
        if (resp.id_usuario_web! > 0) {
          //this.showLoader = true;
          return resp;
        }
      }),
      //  SC CATWEB-382 Se modifico para capturar y retornar todos los ErrorService.
      catchError((err) => {
        console.warn(err.message);
        return of(err);
        
      })
    );
  }

  // SC CATWEB-305 login usuario administrador.
  loginAdmin(usuario: string, password: string) {
    const url = `${this.urlCart}/auth/login`;
    const body = { email: usuario, password: password };
    return this.http.post<AdminModel>(url, body).pipe(
      map((resp) => {
        if (resp.status) {
          //this.showLoader = true;
          return resp;
        } else {
          //this.showLoader = false;
          return resp.message;
        }
      }),
      catchError((err) => of(err.error))
    );
  }

  login(usuario: string, pass: string) {
    const url = `${this.baseUrl}/Login`;
    const body = { usuario: usuario, pass: pass };
    const params = new HttpParams().set('Access_Token', this.accessToken);
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json; charset=utf-8');
    // headers.set('Access-Control-Request-Method', 'POST' );

    return this.http.post<AuthModel>(url, body, { params: params });
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageData);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
    //window.location.reload();
    //window.open(this.configService.config.cliente.exit_page);
  }

  register(codigo: number, usuario: string, clave: string, email: string) {
    const url = `${this.baseUrl}/UsuarioWeb`;
    const body = { codigo, usuario, clave, email, id_rol_usr_web: 1 };
    const params = new HttpParams().set('Access_Token', this.accessToken);
    return this.http.post<any>(url, body, { params: params }).pipe(
      map((resp) => resp.id_usuario_web),
      catchError((err) => of(err.error))
    );
  }

  registerNuevoUsuario(nuevoUsuario: NuevoUsuario) {
    const url = `${this.baseUrl}/Cliente`;
    const body = { ...nuevoUsuario };
    const params = new HttpParams().set('Access_Token', this.accessToken);
    return this.http.post<any>(url, body, { params: params }).pipe(
      map((resp) => {
        const respuesta = {
          id_cliente: resp.cliente.id_cliente,
          codigo_cliente: resp.cliente.codigo,
          nombre_cliente: resp.cliente.nombre,
          email: resp.usuario_web.email,
          usuario_web: resp.usuario_web.usuario,
        };
        return respuesta;
      }),
      catchError((err) => of(err.error))
    );
  }
  // private methods
  public setAuthFromLocalStorage(auth: any): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      this._usuario = auth;
      localStorage.setItem(this.authLocalStorageData, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): UserModel {
    try {
      const data = localStorage.getItem(this.authLocalStorageData);
      const authData: UserModel = JSON.parse(data!);
      return authData;
    } catch (error) {
      return undefined!;
    }
  }

  // MV CATWEB-347 Renueva el tiempo de caducidad del token
  public refreshToken() {
    let data: UserModel = this.getAuthFromLocalStorage();
    data.expire_token =
      Date.now() + this.configService.config.sesion.session_times * 1000;
    this.setAuthFromLocalStorage(data);
  }

  public mostrarLogin() {
    this.muestraLogin = true;
  }
}

<div class="row">
    <div div class="col-sm-12 col-xl-12 xl-100">
      <ngb-carousel *ngIf="banners" [showNavigationArrows]="true" [showNavigationIndicators]="false">
        <ng-template ngbSlide *ngFor="let image of banners">
          <div class="picsum-img-wrapper">
            <img [src]="image.name" alt="Random slide" class="img-fluid">
          </div>
         
          <div class="carousel-caption d-none d-md-block" [ngClass]="{'fondo': image.tittle != ''}">
            <h1>{{image.tittle}}</h1>
            <p>{{image.description}}</p>
          </div>
        
        </ng-template>
      </ngb-carousel>
  </div>
  </div>
  <br />

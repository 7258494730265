import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree  } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/auth/user.model';
import { AuthService } from '../services/auth/auth.service';
import { CarritoService } from '../services/carrito/carrito.service';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {

  router: any;
  
  constructor(public authService: AuthService, private carritoService: CarritoService, private configService: ConfigService) { }
  
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.canActivate(childRoute, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authService.usuario;
      const fechaAhora = Date.now();
      if (currentUser.codigo && fechaAhora <= currentUser.expire_token) {
        this.authService.refreshToken();
        return true;
      }else if (currentUser.expire_token === 0) {
        return true;
      }
      this.carritoService.emptyCartInv();
      this.authService.logout();
      return false;
    }

    canLoad(
      route: Route,
      segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return true;
    }


  // canActivate(next: ActivatedRouteSnapshot, 
  //     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   // Guard for user is login or not
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   if (!user || user === null) {
  //     this.router.navigate(['/auth/login']);
  //     return true
  //   }
  //   else if (user) {
  //     if (!Object.keys(user).length) {
  //       this.router.navigate(['/auth/login']);
  //       return true
  //     }
  //   }
  //   return true
  // }
  
}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductoModel } from 'src/app/shared/models/producto/producto.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CarritoService } from 'src/app/shared/services/carrito/carrito.service';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from 'src/app/shared/services/producto/producto.service';
import { EquivalenteModel } from 'src/app/shared/models/producto/equivalente.model';
import { AplicacionModel } from 'src/app/shared/models/producto/aplicacion.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ComingSoonModule } from 'src/app/pages/coming-soon/coming-soon.module';
import { ImagenModel } from 'src/app/shared/models/producto/imagen.model';
import { SucursalService } from 'src/app/shared/services/sucursal/sucursal.service';


@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {
  
  @Input() muestraFinal;
  @Input() muestraLista;
  @Input() muestraNeto;
  @Input() mostrarPrecio;

  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;
  public closeResult: string;
  public modalOpen: boolean = false;
  public producto: ProductoModel;
  public counter: number = 1;

  public equivalentes: EquivalenteModel[] = [];
  public relacionadas: EquivalenteModel[] = [];
  public aplicaciones: AplicacionModel[] = [];

  public hasEquivalente: boolean = false;
  public hasRelacionada: boolean = false;
  public hasAplicacion: boolean = false;
  public controlStock: boolean = this.configService.config.productos.control_stock;
  public tituloSinStock: string = this.configService.config.productos.titulo_sin_stock;

  public config = this.configService.config;
  public invitado: boolean = false;


    // SC CATWEB-337 CATWEB-351 10/09/2021 arreglo de imagenes del producto.
    public images: ImagenModel[] = [];
    // SC CATWEB-350 CATWEB-351 14/09/2021 arreglo que acumula una pila de productos utilizada para volver a los productos anterior.
    public backProducts = [];

  // SC CATWEB-350 CATWEB-351 14/09/2021 variable de datos para realizar la busqueda de un producto.
  private datos = {
    Pagina: '0',
    Cant_Registros: 1,
    Nombre: '',
    Id_Marca: -1,
    Codigo: '',
    Codigo_Fabricante: '',
    Con_Stock: 'S',
    Id_Familia: -1,
    Incluye_SubFamilia: 'N',
    Codigo_Nombre_Descripcion: '',
    Id_Cliente: this.authService.usuario.id_cliente,
    Orden: 1,
    id_marca_aplic: -1,
    solo_con_aplic: 'N',
    nro_pieza: '',
    aplicacion: '',
    Id_Sucursal:-1 //SC CATWEB-410 se agrega parametro para consultar por sucursal.
  };

  constructor(private modalService: NgbModal,
    private authService: AuthService,
    private carritoService: CarritoService,
    private productoService: ProductoService,
    private configService: ConfigService,
    public toastr: ToastrService,
    private sucursalService: SucursalService) { }

  ngOnInit(): void {
    this.invitado = this.authService.usuario.invitado;
    // SC CATWEB-410 si esta habilitado el modo por sucursal en sinergial se debe obtener la sucursal para realizar la consulta de los productos
    if(this.authService.usuario.modo_por_sucursal){
      // this.sucursalService.currentDataSucSelect$.subscribe((resp) =>{
      //   this.datos.id_sucursal = resp.id_sucursal;
      // });
      this.datos.Id_Sucursal = parseInt(this.authService.usuario.ids_sucursales.split(',',1)[0]);
    }
  }

  openModal(producto: ProductoModel) {
    this.backProducts = [];
    this.cargarDatosProductos(producto);
    this.modalOpen = true;
    this.modalService.open(this.QuickView, { 
    size: 'lg',
    ariaLabelledBy: 'modal-basic-title',
    centered: true,
    scrollable: false,
    windowClass: 'Quickview'
    }).result.then((result) => {
      `Result ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public increment() {
    
      this.counter += 1;

  }

  public decrement() {
    
      if (this.counter > 1) {
        this.counter -= 1;
      }

  }

  add(producto){
    if(this.counter > 0){
      if(this.carritoService.validarStock(this.counter, producto.stock)){
        let item =  {
          "id_cliente": this.authService.usuario.id_cliente,
          "id_producto": producto.id_articulo,
          "nombre":producto.nombre,
          "codigo":producto.codigo,
          "imagen":producto.imagen1,
          "precio": producto.precio_final,
          "cantidad": this.counter,
          "stock":producto.stock
      };
        const result= this.carritoService.changeCart(item, true);
      if(result){
        this.toastr.success(`${producto.nombre} `+ CarritoService.MSG_ADD_CART, '', {
          timeOut: 2000,
        });
      }else{
        this.toastr.error(CarritoService.MSG_ERROR_CART);
      }
      }else{
        this.toastr.error(CarritoService.MSG_ERROR_STOCK);
      }
    }else{
      this.toastr.error(CarritoService.MSG_ERROR_CANT_MIN);
    }
    

  }

  // SC CATWEB-316 / CATWEB-317 Funcion que obtiene sugun el tipo_relacion (E o R), los productos equivalentes(E), o Relacionados (R) de un producto. 
  cargarEquivalentes(id_articulo:number, tipo_relacion: string, codigo: string){
   const susc = this.productoService.getEquivalentes(tipo_relacion, id_articulo, codigo).subscribe((resp) => {
      
      switch (tipo_relacion) {
        case 'E':
          if(resp.length > 0){
            this.equivalentes = resp;
          }else{
            this.equivalentes = [];
            this.hasEquivalente = false;
          }
          
          break;
      
        case 'R':
          if(resp.length > 0){
          this.relacionadas = resp;
          }else{
            this.relacionadas = [];
            this.hasRelacionada = false;
          }
        default:
          break;
      }
    });
    susc.unsubscribe;
  }

  // SC CATWEB-318 Funcion que obtiene arreglo de aplicaciones de un producto.
  cargarAplicacion(id_articulo:number){
    
    const susc = this.productoService.getAplicaciones(id_articulo).subscribe((resp) => {
      if(resp.length > 0){
        this.aplicaciones = resp;
      }else{
        this.aplicaciones = [];
        this.hasAplicacion = false;
      }
      
    });
    susc.unsubscribe;
  }

  // SC CATWEB-318 Se carga segun tab los arreglos, actualmente no se usa dado que los numeros de tab se incrementan (ver si se puede fijar los numeros de tab).
  selectTab(event){
    // console.log(event);
    switch (event.nextId) {
      case "ngb-tab-0":
        this.cargarAplicacion(this.producto.id_articulo);
        break;
    
      case "ngb-tab-1":
        this.cargarEquivalentes(this.producto.id_articulo, 'E', this.producto.codigo);
        break;
      case "ngb-tab-2":
        this.cargarEquivalentes(this.producto.id_articulo, 'R', this.producto.codigo);
        break;
      default:
        break;
    }
  }

  // SC CATWEB-318 Funcion inicializa arreglos de aplicacion, equivalentes y relacionadas.
  inicializar(){

    this.cargarImagenesProducto(this.producto.id_articulo);
    
    if(this.hasAplicacion){
      this.cargarAplicacion(this.producto.id_articulo);
    }
    if(this.hasEquivalente){
      this.cargarEquivalentes(this.producto.id_articulo, 'E', this.producto.codigo);
    }
    if(this.hasRelacionada){
      this.cargarEquivalentes(this.producto.id_articulo, 'R', this.producto.codigo);
    }

  }

  // SC CATWEB-350 CATWEB-351 14/09/2021 Funcion carga el modal con el producto equivalente / relacionado.
  openModal2(codigo : number){
    
    this.datos.Codigo_Nombre_Descripcion = codigo.toString();
    const suc = this.productoService.getProductos(this.datos).subscribe(resp => {
      if(resp.length > 0){
        this.backProducts.push(this.producto.codigo);
        this.cargarDatosProductos(resp[0]);
      }else{
        this.toastr.error('Producto no publicado');
      }
    }
    );
    suc.unsubscribe;
  }

  // SC CATWEB-350 CATWEB-351 14/09/2021 Funcion recarga el modal con el producto anterior.
  back(){
    this.datos.Codigo_Nombre_Descripcion = this.backProducts.pop().toString();
    const suc = this.productoService.getProductos(this.datos).subscribe(resp => {
      if(resp.length > 0){
        this.cargarDatosProductos(resp[0]);
      }else{
        this.toastr.error('Producto no publicado');
      }
  }
  );
  suc.unsubscribe;
}
// SC CATWEB-350 CATWEB-351 14/09/2021 Funcion inicializa y carga el producto en el modal
  cargarDatosProductos(producto:ProductoModel){


    // SC CATWEB-318 / CATWEB-316 / CATWEB-317se inicializan los arreglos a vacios.
    this.aplicaciones = [];
    this.relacionadas = [];
    this.equivalentes = [];
    this.images = []
    // SC CATWEB-324 se setea el producto que se recibe como parametro al abrir al modal y se inicializa el contador de cantidad de productos para sumar al carrito.
    this.producto = producto;
    this.counter = 1;
    
    // SC CATWEB-318 / CATWEB-316 / CATWEB-317 setean las variables que indican si tienen o no aplicaciones, equivalentes o relacionadas.
    this.hasAplicacion = producto.tiene_aplic == 'S'? true: false;
    this.hasEquivalente = producto.tiene_equivalencias== 'S'? true: false;
    this.hasRelacionada = producto.tiene_part_rel== 'S'? true: false;
    
    this.inicializar();

  }

  // MV CATWEB 364 Funcion que obtiene las imagenes del producto y las guarda en el arreglo de imagenes
  cargarImagenesProducto(id_articulo: number){
    
    const susc = this.productoService.getImagenesArticulo(id_articulo).subscribe(imagenes => {
      if(imagenes.length > 0){
        this.images = imagenes;
        let id = 1;
        this.images.forEach(img => {
          img.id = id;
          id++;
        });
      } else{
        this.images = [{id: 1,nombre: "assets/images/productos/default.png", tipo_media: "I"}];
      }
    });
    susc.unsubscribe;
  }

  // Opciones de configuracion carousel de imagenes
  owlcarousel14Options = {
    items: 1,
    margin: 10,
    autoHeight: false,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    loop: true,
  }

}


<ng-container *ngIf="logintheme == 1">
  <app-login-simple
    [invitado]="invitado"
    [registro]="registro"
    (login)="login2($event)"
    (loginInv)="loginInvitado()"
    [usuariosGuardados]="usuariosGuardados"
    [tituloLogin]="tituloLogin"
    [textoLogin]="textoLogin"
  ></app-login-simple>
</ng-container>
<ng-container *ngIf="logintheme == 2">
  <app-login-image-two
    [invitado]="invitado"
    [registro]="registro"
    (login)="login2($event)"
    (loginInv)="loginInvitado()"
    [tituloLogin]="tituloLogin"
    [textoLogin]="textoLogin"
    [usuariosGuardados]="usuariosGuardados"
  ></app-login-image-two>
</ng-container>
<app-customizer></app-customizer>

import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/shared/models/config.model';
import { SucursalModel } from 'src/app/shared/models/sucursal/sucursal.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { SucursalService } from 'src/app/shared/services/sucursal/sucursal.service';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  invitado = false;
  admin = false;
  public exitPage: string;
  // SC CATWEB-410
  public sucursal:SucursalModel;

  get usuario(){
    return this.authService.usuario;
  }

  public openNotification: boolean = false;

  constructor(private authService: AuthService, private configService:ConfigService, private sucursalService: SucursalService) { }

  ngOnInit() {
    this.invitado = this.authService.usuario.invitado;

    this.admin = this.authService.usuario.admin;
    
    this.exitPage = this.configService.config.cliente.exit_page;

    // SC CATWEB-410
    if(this.usuario.modo_por_sucursal){
      this.sucursalService.getSucursalesByIds(this.usuario.ids_sucursales);
      setTimeout(() => {
        this.sucursalService.currentDataSucSelect$.subscribe((resp) => {
          this.sucursal = resp;
          console.log(resp);
        });
      }, 2000);
      
    }
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  logout(){
    localStorage.removeItem("data");
  }

}

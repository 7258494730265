<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              <h1>{{config.cliente!.titulo_page}}</h1>
            </a>
          </div>
          <div class="login-main">
            <!-- SC CATWEB-302 Formulario para el registro de clientes de Sinergial -->
            <form
              class="theme-form"
              autocomplete="off"
              [formGroup]="formRegister"
              (ngSubmit)="register()"
            >
              <h4>Crear Cuenta Cliente</h4>
              <p>Ingrese sus datos para crear una cuenta</p>
              <div class="form-group">
                <label class="col-form-label pt-0">Código Cliente</label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="código cliente"
                  formControlName="codigo"
                  [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'codigo')"
                  [class.is-valid]="validaciones.isControlValid(formRegister, 'codigo')"
                  mask="9{10}"
                />
                <!-- SC CATWEB-302 Validaciones Código-->
                <div class="valid-feedback" *ngIf="validaciones.isControlValid(formRegister, 'codigo')">
                  Código correcto
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'codigo', 'required')"
                >
                  Código requerido
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'codigo', 'maxlength')"
                >
                  Código debe tener menos de 10 digitos
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'codigo', 'minlength')"
                >
                  Código debe tener mas de 5 digitos
                </div>
                <!-- SC CATWEB-302 Validaciones Codigo End -->
              </div>
              <div class="form-group">
                <label class="col-form-label">Usuario</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="usuario"
                  formControlName="usuario"
                  [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'usuario')"
                  [class.is-valid]="validaciones.isControlValid(formRegister, 'usuario')"
                />
                <!-- SC CATWEB-302 Validaciones usuario -->
                <div class="valid-feedback" *ngIf="validaciones.isControlValid(formRegister, 'usuario')">
                  Usuario correcto
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'usuario', 'required')"
                >
                  Usuario requerido
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'usuario', 'maxlength')"
                >
                  Usuario máximo 20 caracteres
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'usuario', 'minlength')"
                >
                  Usuario al menos 5 caracteres
                </div>
                <!-- SC CATWEB-302 Validaciones usuario End. -->
              </div>
              <div class="form-group">
                <label class="col-form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  formControlName="email"
                  [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'email')"
                  [class.is-valid]="validaciones.isControlValid(formRegister, 'email')"
                />
                <!-- SC CATWEB-302 Validaciones email -->
                <div class="valid-feedback" *ngIf="validaciones.isControlValid(formRegister, 'email')">
                  Email correcto
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'email', 'required')"
                >
                  Email requerido
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'email', 'pattern')"
                >
                  Email no válido
                </div>
                <!-- SC CATWEB-302 Validaciones email End.-->
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="********"
                  formControlName="pass"
                  [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'pass')"
                  [class.is-valid]="validaciones.isControlValid(formRegister, 'pass')"
                />
                <!-- SC CATWEB-302 Validaciones pass -->
                <div class="valid-feedback" *ngIf="validaciones.isControlValid(formRegister, 'pass')">
                  Contraseña valida
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'pass', 'required')"
                >
                  Contraseña requerida
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'pass', 'minlength')"
                >
                  Contraseña al menos 5 caracteres
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'pass', 'maxlength')"
                >
                  Contraseña máximo 10 caracteres
                </div>
                <!-- SC CATWEB-302 Validaciones pass End. -->
                <!-- SC CATWEB-302 Muestra/oculta la contraseña -->
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
              </div>
              <!-- SC CATWEB-302 Muestra/oculta la contraseña End -->
              <div class="form-group">
                <label class="col-form-label">Repetir Contraseña</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="********"
                  formControlName="retypePass"
                  [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'retypePass')"
                  [class.is-valid]="validaciones.isControlValid(formRegister, 'retypePass')"
                />
                <!-- SC CATWEB-302 Validaciones repetir contraseña -->
                <div
                  class="valid-feedback"
                  *ngIf="validaciones.isControlValid(formRegister, 'retypePass')"
                >
                  Contraseña valida
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'retypePass', 'required')"
                >
                  Contraseña requerida
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'retypePass', 'minlength')"
                >
                  Contraseña dal menos 5 caracteres
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="validaciones.controlHasError(formRegister, 'retypePass', 'maxlength')"
                >
                Contraseña máximo 10 caracteres
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    formRegister.controls['retypePass'].errors &&
                    formRegister.controls['retypePass'].errors.ConfirmPassword
                  "
                >
                  Contraseña no coinciden
                </div>
                <!-- SC CATWEB-302 Validaciones repetir contraseña end -->
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Agree with<a class="ml-2" routerLink='/'>Privacy Policy</a></label>
                </div> -->
                <!-- SC CATWEB-302 Recaptcha -->
                <ngx-recaptcha2
                  #captchaElem
                  [siteKey]="siteKey"
                  formControlName="recaptcha"
                >
                </ngx-recaptcha2>
                <!-- SC CATWEB-302 Recaptcha end-->
                <br />
                <button class="btn btn-primary btn-block" type="submit" [disabled]= "formRegister.invalid">
                  Crear Cuenta
                </button>
              </div>
              <!-- SC CATWEB-302 Link regresa al login -->
              <p class="mt-4 mb-0">
                Tienes una cuenta?<a class="ml-2" [routerLink]="'/auth/login'"
                  >Ingresar</a
                >
              </p>
            </form>
            <!-- SC CATWEB-302 Formulario para el registro de clientes de Sinergial End. -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

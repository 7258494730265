<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              <h1>{{config.cliente!.titulo_page}}</h1>
            </a>
          </div>
          <div class="login-main">
            <!-- MV CATWEB-321 Formulario para el registro de nuevo usuario web no registrado en sinergial -->
            <form class="theme-form" autocomplete="off" [formGroup]="formRegister" (ngSubmit)="register()">

              <div class="row">
                <h4>Crear Cuenta Nuevo Cliente</h4>
                <p>Ingrese sus datos para crear una cuenta</p>
              </div>

              <!-- MV CATWEB-321 Datos Personales Formulario-->

              <div formGroupName="cliente">

                <h5 class="row">Datos Personales</h5>
                <hr>

                <div class="row">
                  <!-- MV CATWEB-321 Nombre-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="nombre" class="col-form-label pt-0">Nombre</label>
                    <input type="text" class="form-control" id="nombre" placeholder="Nombre" formControlName="nombre"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.nombre')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.nombre')">
                    <!-- MV CATWEB-321 Validaciones Nombre-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.nombre', 'required')">
                      Nombre requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.nombre', 'maxlength')">
                      Nombre debe tener menos de 30 digitos
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Apellido-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="apellido" class="col-form-label pt-0">Apellido</label>
                    <input type="text" class="form-control" id="apellido" placeholder="Apellido"
                      formControlName="apellido" [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.apellido')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.apellido')">
                    <!-- MV CATWEB-321 Validaciones Apellido-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.apellido', 'required')">
                      Apellido requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.apellido', 'maxlength')">
                      Apellido debe tener menos de 30 digitos
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Sexo-->
                  <div class="col-12 col-md-6 mb-2">
                    <label class="col-form-label pt-0">Sexo</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="masculino" value="masculino"
                        formControlName="sexo">
                      <label class="form-check-label" for="masculino">
                        Masculino
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="femenino" value="femenino"
                        formControlName="sexo">
                      <label class="form-check-label" for="femenino">
                        Femenino
                      </label>
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Fecha Nacimiento-->
                  <div class="col-12 col-md-6 mb-2">
                    <label for="fecha_nacimiento" class="col-form-label pt-0">Fecha de Nacimiento</label>
                    <input type="date" class="form-control" id="fecha_nacimiento" placeholder="dd/mm/yyyy"
                      formControlName="fecha_nacimiento"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.fecha_nacimiento')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.fecha_nacimiento')">
                    <!-- MV CATWEB-321 Validaciones Fecha-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.fecha_nacimiento', 'required')">
                      Fecha de nacimiento requerida
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.fecha_nacimiento', 'MenorDeEdad')">
                      Debes ser mayor de edad
                    </div>
                  </div>
                </div>

                <div class="row">

                  <!-- MV CATWEB-321 Responsable IVA-->
                  <div class="col-12 col-md-4 mb-2 form-group">
                    <label class="col-form-label pt-0 mt-3">Resp. IVA</label>
                    <select class="form-control" formControlName="id_respiva" #respiva
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.id_respiva')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.id_respiva')">
                      <option value=1 selected>Consumidor Final</option>
                      <option value=5>Exento</option>
                      <option value=4>Inscripto</option>
                      <option value=2>Monotributo</option>
                    </select>
                    <!-- MV CATWEB-321 Validaciones Resp. IVA-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.id_respiva', 'required')">
                      Resp. IVA requerido
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Tipo Documento-->
                  <div class="col-12 col-md-4 mb-2 form-group">
                    <label class="col-form-label pt-0 mt-3">Tipo</label>
                    <select class="form-control" formControlName="id_tipo_doc"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.id_tipo_doc')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.id_tipo_doc')" *ngIf="respiva.value == 1">
                      <option value="3">DNI</option>
                      <option value="4">LC</option>
                      <option value="5">LE</option>
                    </select>
                    <select class="form-control" formControlName="id_tipo_doc"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.id_tipo_doc')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.id_tipo_doc')" *ngIf="respiva.value != 1">
                      <option value="1">CUIT</option>
                      <option value="2">CUIL</option>
                    </select>
                    <!-- MV CATWEB-321 Validaciones Tipo-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.id_tipo_doc','required')">
                      Tipo requerido
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Numero-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="numeroDoc" class="col-form-label pt-0">Número</label>
                    <input type="text" class="form-control" id="numeroDoc" placeholder="Número"
                      formControlName="numero_doc" [dropSpecialCharacters]="false" mask="00.000.000"
                      *ngIf="respiva.value == 1" [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.numero_doc')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.numero_doc')">
                    <input type="text" class="form-control" id="numeroDoc" placeholder="Número"
                      formControlName="numero_doc" [dropSpecialCharacters]="false" mask="00-00000000-0"
                      *ngIf="respiva.value != 1" [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.numero_doc')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.numero_doc')">
                    <!-- MV CATWEB-321 Validaciones Numero-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.numero_doc', 'required')">
                      Número requerido
                    </div>
                  </div>


                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Calle-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="calle" class="col-form-label pt-0">Calle</label>
                    <input type="text" class="form-control" id="calle" placeholder="Calle" formControlName="calle"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.calle')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.calle')">
                    <!-- MV CATWEB-321 Validaciones Calle-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.calle', 'required')">
                      Calle requerida
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.calle', 'maxlength')">
                      Calle debe tener menos de 30 digitos
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Altura-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="altura" class="col-form-label pt-0">Altura</label>
                    <input type="text" class="form-control" id="altura" placeholder="Altura" formControlName="numero"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.numero')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.numero')" mask="0*">
                    <!-- MV CATWEB-321 Validaciones Altura-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.numero', 'required')">
                      Altura requerida
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.numero', 'maxlength')">
                      Altura debe tener menos de 6 digitos
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Barrio-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="barrio" class="col-form-label pt-0">Barrio</label>
                    <input type="text" class="form-control" id="barrio" placeholder="Barrio" formControlName="barrio" mask="A{30}">
                  </div>
                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Torre-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="torre" class="col-form-label pt-0">Torre</label>
                    <input type="text" class="form-control" id="torre" placeholder="Torre" formControlName="torre" mask="A{3}">
                  </div>

                  <!-- MV CATWEB-321 Piso-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="piso" class="col-form-label pt-0">Piso</label>
                    <input type="text" class="form-control" id="piso" placeholder="Piso" formControlName="piso" mask="0{2}">
                  </div>

                  <!-- MV CATWEB-321 Departamento-->
                  <div class="form-group mt-3 col-12 col-md-4 mb-2">
                    <label for="departamento" class="col-form-label pt-0">Departamento</label>
                    <input type="text" class="form-control" id="departamento" placeholder="Departamento"
                      formControlName="departamento" mask="A{3}">
                  </div>
                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Provincia-->
                  <div class="col-12 col-md-6 mb-2 form-group ">
                    <label class="col-form-label pt-0 mt-3">Provincia</label>
                    <select class="form-control" [formControl]="provincia">
                      <option value="0" disabled selected>Seleccione una povincia</option>
                      <option [value]="provincia.id_provincia" *ngFor="let provincia of provincias">{{provincia.nombre}}
                      </option>
                    </select>
                  </div>
                  <!-- MV CATWEB-321 Ciudad-->
                  <div class="col-12 col-md-6 mb-2 form-group">
                    <label class="col-form-label pt-0 mt-3">Ciudad</label>
                    <select class="form-control" formControlName="id_localidad"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.id_localidad')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.id_localidad')">
                      <option [value]="ciudad.id_localidad" *ngFor="let ciudad of localidades">{{ciudad.nombre}}
                      </option>
                    </select>
                    <!-- MV CATWEB-321 Validaciones Ciudad-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.id_localidad', 'required')">
                      Ciudad requerida
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Telefono-->
                  <div class="form-group mt-3 col-12 col-md-6 mb-2">
                    <label for="telefono" class="col-form-label pt-0">Teléfono</label>
                    <input type="text" class="form-control" id="telefono" placeholder="Ej: 0358 4123456"
                      formControlName="telefono" mask="0000 0000000"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.telefono')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.telefono')">
                    <!-- MV CATWEB-321 Validaciones Telefono-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.telefono', 'required')">
                      Teléfono requerido
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Email-->
                  <div class="form-group mt-3 col-12 col-md-6 mb-2">
                    <label for="email" class="col-form-label pt-0">Email</label>
                    <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'cliente.email')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'cliente.email')">
                    <!-- MV CATWEB-321 Validaciones Email-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.email', 'required')">
                      Email requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'cliente.email', 'pattern')">
                      Email no válido
                    </div>
                  </div>
                </div>
                <hr>

              </div>
              <!-- MV CATWEB-321 Datos Personales Formulario END-->

              <!-- MV CATWEB-321 Datos Cuenta Formulario-->

              <div formGroupName="usuario_web">

                <h5 class="row">Datos de Cuenta</h5>
                <hr>

                <div class="row">
                  <!-- MV CATWEB-321 Usuario-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="usuario" class="col-form-label pt-0">Usuario</label>
                    <input type="text" class="form-control" id="usuario" placeholder="Usuario" formControlName="usuario"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'usuario_web.usuario')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'usuario_web.usuario')">
                    <!-- MV CATWEB-321 Validaciones Usuario -->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.usuario', 'required')">
                      Usuario requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.usuario', 'maxlength')">
                      Usuario como maximo 2 caracteres
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.usuario', 'minlength')">
                      Usuario al menos 5 caracteres
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Email-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="email2" class="col-form-label pt-0">Email</label>
                    <input type="email" class="form-control" id="email2" placeholder="Email" formControlName="email"
                    [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'usuario_web.email')"
                    [class.is-valid]="validaciones.isControlValid(formRegister, 'usuario_web.email')">
                    <!-- MV CATWEB-321 Validaciones Email-->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.email', 'required')">
                      Email requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.email', 'pattern')">
                      Email no válido
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- MV CATWEB-321 Contraseña-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="contraseña" class="col-form-label pt-0">Contraseña</label>
                    <input [type]="show ? 'text' : 'password'" class="form-control" id="contraseña"
                      placeholder="********" formControlName="clave"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'usuario_web.clave')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'usuario_web.clave')">
                    <!-- MV CATWEB-321 Validaciones Clave -->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.clave', 'required')">
                      Contraseña requerida
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.clave', 'minlength')">
                      Contraseña al menos 5 caracteres
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.clave', 'maxlength')">
                      Contraseña como maximo 10 caracteres
                    </div>
                    <!-- MV CATWEB-321 Muestra/oculta la contraseña -->
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show">
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </div>
                  </div>

                  <!-- MV CATWEB-321 Repetir Contraseña-->
                  <div class="form-group col-12 col-md-6 mb-2">
                    <label for="contraseñaRep" class="col-form-label pt-0">Repetir Contraseña</label>
                    <input [type]="show ? 'text' : 'password'" class="form-control" id="contraseñaRep"
                      placeholder="********" formControlName="retypeClave"
                      [class.is-invalid]="validaciones.isControlInvalid(formRegister, 'usuario_web.retypeClave')"
                      [class.is-valid]="validaciones.isControlValid(formRegister, 'usuario_web.retypeClave')">
                    <!-- MV CATWEB-321 Validaciones Clave -->
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.retypeClave', 'required')">
                      Contraseña requerida
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister, 'usuario_web.retypeClave', 'minlength')">
                      Contraseña al menos 5 caracteres
                    </div>
                    <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRegister,'usuario_web.retypeClave', 'maxlength')">
                      Contraseña como maximo 10 caracteres
                    </div>
                    <div class="invalid-feedback" *ngIf="
                    formRegister.get('usuario_web.retypeClave').errors &&
                    formRegister.get('usuario_web.retypeClave').errors.ConfirmPassword">
                      Contraseña no coinciden
                    </div>
                    <!-- MV CATWEB-321 Muestra/oculta la contraseña -->
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show">
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-0">
                  <!-- MV CATWEB-321 Recaptcha -->
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                  </ngx-recaptcha2>
                  <!-- MV CATWEB-321 Recaptcha end-->
                  <!-- MV CATWEB-321 Datos Cuenta Formulario END-->
                </div>
                <br />
                <button class="btn btn-primary btn-block" type="submit" [disabled]="formRegister.invalid">
                  Crear Cuenta
                </button>
                <p class="mt-4 mb-0">
                  Tienes una cuenta?<a class="ml-2" [routerLink]="'/auth/login'"
                    >Ingresar</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="customizer-links" [ngClass]="{'open': customizer != '' }">
   <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
      <ng-container *ngIf="!admin; else config">
         <a class="nav-link" [routerLink]="'/authentication/login/config'" data-original-title="" title="">
            <div> <i class="icon-settings"></i></div>
            <span>Configuraci&oacute;n</span> 
         </a>
      </ng-container>
      <ng-template #config>
         <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer == 'layout' }" (click)="Customizer('layout')">
            <div class="settings"><i class="icon-paint-bucket"></i></div> 
            <span>Check layouts</span>
         </a>
         <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer == 'option' }" id="c-pills-home-tab" (click)="Customizer('option')">
            <div class="settings"><i class="icon-settings"></i></div>
            <span>Quick option</span> 
         </a>
         <a class="nav-link" target="_blank" href="https://pixelstrap.freshdesk.com/" data-original-title="" title="">
            <div> <i class="icon-support"></i></div>
            <span>Support</span> 
         </a>
         <a class="nav-link" target="_blank" href="http://docs.pixelstrap.com/angular/cuba/document/index.html" data-original-title="" title="">
            <div> <i class="icon-settings"></i></div>
            <span>Document</span> 
         </a>
         <a class="nav-link" target="_blank" href="http://admin.pixelstrap.com/cuba/theme/landing-page.html#frameworks" data-original-title="" title="">
            <div> <i class="icon-panel"></i></div>
            <span>Check features</span> 
         </a>
         <a class="nav-link" target="_blank" href="https://1.envato.market/3GVzd" data-original-title="" title="">
            <div> <i class="icon-shopping-cart-full"></i></div>
            <span>Buy now</span> 
         </a>
      </ng-template>
   </div>
</div>
<ng-template #config>
   <div class="customizer-contain" [ngClass]="{'open' : customizer != '' }">
      <div class="tab-content" id="c-pills-tabContent">
         <div class="customizer-header">
            <i class="icofont-close icon-close" (click)="Customizer('')"></i>
            <h5>Preview Settings</h5>
            <p class="mb-0">Try It Real Time <i class="fa fa-thumbs-o-up txt-primary"></i></p>
            <button class="btn btn-primary plus-popup mt-2" (click)="openModal(popup)">Configuration</button>
            <ng-template #popup let-modal>
               <div class="modal-header modal-copy-header">
                  <h5 class="headerTitle mb-0">Customizer configuration</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="modal-header config-popup">
                     <p>To replace our design with your desired theme. Please do configuration as mention </p>
                     <p> <b> Path : src > app > shared > services > layout.service.ts </b> </p>
                     <div>
                        <pre>
   <code>
         public config = &#123;
            settings&#58; &#123;
               layout&#58; '{{layout.config.settings.layout}}',
               layout_type&#58; '{{layout.config.settings.layout_type}}',
               layout_version&#58; '{{layout.config.settings.layout_version}}',
               sidebar_type&#58; '{{layout.config.settings.sidebar_type}}',
            &#125;,
            color&#58; &#123;
               primary_color&#58; '{{layout.config.color.primary_color}}',
               secondary_color&#58; '{{layout.config.color.secondary_color}}'
            &#125;
         &#125;
   </code>
                     </pre>
                     </div>
                  </div>
               </div>
            </ng-template>
         </div>
         <div class="customizer-body custom-scrollbar">
            <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'layout' }" id="c-pills-layouts" role="tabpanel">
               <ul class="sidebar-type layout-grid layout-types">
                  <li data-attr="compact-sidebar">
                     <div class="layout-img"> 
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Dubai' }">
                           <img src="assets/images/layouts/1.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Dubai</h6>
                     </div>
                  </li>
                  <li class="only-body" data-attr="default-body">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'London' }">
                           <img src="assets/images/layouts/2.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>London</h6>
                     </div>
                  </li>
                  <li data-attr="modern-layout">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Seoul' }">
                           <img src="assets/images/layouts/9.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Seoul</h6>
                     </div>
                  </li>
                  <li data-attr="material-layout">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'LosAngeles' }">
                           <img src="assets/images/layouts/10.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Los Angeles</h6>
                     </div>
                  </li>
                  <li data-attr="dark-sidebar">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Paris' }">
                           <img src="assets/images/layouts/3.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Paris</h6>
                     </div>
                  </li>
                  <li data-attr="compact-wrap">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Tokyo' }">
                           <img src="assets/images/layouts/4.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Tokyo</h6>
                     </div>
                  </li>
                  <li data-attr="color-sidebar">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Madrid' }">
                           <img src="assets/images/layouts/5.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Madrid</h6>
                     </div>
                  </li>
                  <li data-attr="compact-small">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Moscow' }">
                           <img src="assets/images/layouts/6.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Moscow</h6>
                     </div>
                  </li>
                  <li data-attr="box-layout" class="box-layout">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'NewYork' }">
                           <img src="assets/images/layouts/7.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>New York</h6>
                     </div>
                  </li>
                  <li data-attr="enterprice-type">
                     <div class="layout-img"> 
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Singapore' }">
                           <img src="assets/images/layouts/8.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Singapore</h6>
                     </div>
                  </li>
                  <li data-attr="material-icon">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Rome' }">
                           <img src="assets/images/layouts/11.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Rome</h6>
                     </div>
                  </li>
                  <li data-attr="advance-type">
                     <div class="layout-img">
                        <a [routerLink]="['/dashboard/default']" [queryParams]="{ layout: 'Barcelona' }">
                           <img src="assets/images/layouts/12.jpg" class="img-fluid" alt="">
                        </a>
                        <h6>Barcelona</h6>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'option' }" id="c-pills-home" role="tabpanel"
               aria-labelledby="c-pills-home-tab">
               <h6>Layout Type</h6>
               <ul class="main-layout layout-grid">
                  <li data-attr="ltr" [ngClass]="{'active': layoutType == 'ltr'}" (click)="customizeLayoutType('ltr')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-light sidebar"></li>
                           <li class="bg-light body"><span class="badge badge-primary">LTR</span></li>
                        </ul>
                     </div>
                  </li>
                  <li data-attr="rtl" [ngClass]="{'active': layoutType == 'rtl'}" (click)="customizeLayoutType('rtl')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-light body"><span class="badge badge-primary">RTL</span></li>
                           <li class="bg-light sidebar"></li>
                        </ul>
                     </div>
                  </li>
                  <li data-attr="Box" class='box-layout' [ngClass]="{'active': layoutType == 'box-layout'}"
                     (click)="customizeLayoutType('box-layout')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-light sidebar"></li>
                           <li class="bg-light body"> <span class="badge badge-primary">BOX</span> </li>
                        </ul>
                     </div>
                  </li>
               </ul>
               <h6>Sidebar Type</h6>
               <ul class="sidebar-type layout-grid">
                  <li data-attr="normal-sidebar" [class.d-none]="screenwidth < 991"
                     [ngClass]="{'active': sidebarType == 'horizontal-wrapper'}"
                     (click)="customizeSidebarType('horizontal-wrapper')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-dark sidebar"></li>
                           <li class="bg-light body"> </li>
                        </ul>
                     </div>
                  </li>
                  <li data-attr="compact-sidebar" [ngClass]="{'active': sidebarType == 'compact-wrapper'}"
                     (click)="customizeSidebarType('compact-wrapper')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-dark sidebar compact"></li>
                           <li class="bg-light body"></li>
                        </ul>
                     </div>
                  </li>
               </ul>
               <h6>Sidebar settings</h6>
               <ul class="sidebar-setting layout-grid">
                  <li data-attr="default-sidebar" [ngClass]="{'active': sidebarSetting == 'default-sidebar'}"
                     (click)="customizeSidebarSetting('default-sidebar')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body bg-light"> <span class="badge badge-primary">Default</span> </div>
                  </li>
                  <li data-attr="border-sidebar" [ngClass]="{'active': sidebarSetting == 'border-sidebar'}"
                     (click)="customizeSidebarSetting('border-sidebar')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body bg-light"> <span class="badge badge-primary">Border</span> </div>
                  </li>
                  <li data-attr="iconcolor-sidebar" [ngClass]="{'active': sidebarSetting == 'iconcolor-sidebar'}"
                     (click)="customizeSidebarSetting('iconcolor-sidebar')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body bg-light"> <span class="badge badge-primary">icon Color</span> </div>
                  </li>
               </ul>
               <h6 class="">Unlimited Color</h6>
               <ul class="layout-grid unlimited-color-layout">
                  <input id="ColorPicker1" [(ngModel)]="primary_color" type="color" value="#7366ff">
                  <input id="ColorPicker2" [(ngModel)]="secondary_color" type="color" value="#f73164">
                  <button type="button" class="color-apply-btn btn btn-primary color-apply-btn" (click)="applyColor()">Apply</button>
                  <button type="button" class="color-apply-btn btn btn-primary color-apply-btn ml-2" (click)="resetColor()">Reset</button>
               </ul>
               <h6>Mix Layout</h6>
               <ul class="layout-grid customizer-mix">
                  <li class="color-layout" data-attr="default" [ngClass]="{'active': MIXLayout == 'default'}"
                     (click)="customizeMixLayout('default')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-light sidebar"></li>
                           <li class="bg-light body"> </li>
                        </ul>
                     </div>
                  </li>
                  <li class="color-layout" data-attr="dark-sidebar" [ngClass]="{'active': MIXLayout == 'dark-sidebar'}"
                     (click)="customizeMixLayout('dark-sidebar')">
                     <div class="header bg-light">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-dark sidebar"></li>
                           <li class="bg-light body"> </li>
                        </ul>
                     </div>
                  </li>
                  <li class="color-layout" data-attr="dark-only" [ngClass]="{'active': MIXLayout == 'dark-only'}"
                     (click)="customizeMixLayout('dark-only')">
                     <div class="header bg-dark">
                        <ul>
                           <li></li>
                           <li></li>
                           <li></li>
                        </ul>
                     </div>
                     <div class="body">
                        <ul>
                           <li class="bg-dark sidebar"></li>
                           <li class="bg-dark body"> </li>
                        </ul>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</ng-template>


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucursal',
  templateUrl: './sucursal.component.html',
  styleUrls: ['./sucursal.component.scss']
})
export class SucursalComponent implements OnInit {

  public openNotification: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }


}

import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('../../components/productos/producto.module').then(m => m.ProductoModule)
  },
  {
    path: 'Comprobantes',
    loadChildren: () => import('../../components/comprobantes/comprobante.module').then(m => m.ComprobanteModule)
  },
  {
    path: 'cuenta-corriente',
    loadChildren: () => import('../../components/cuenta_corriente/ctacte.module').then(m => m.CtacteModule)
  },
  {
    path: 'mi-carrito',
    loadChildren: () => import('../../components/mi_carrito/cart.module').then(m => m.CartModule)
  },
  {
    path: 'ofertas-novedades',
    loadChildren: () => import('../../components/ofertas_novedades/ofertas-novedades.module').then(m => m.OfertasNovedadesModule)
  },
  {
    path: 'cuenta-usuario',
    loadChildren: () => import('../../components/mi_cuenta/mi-cuenta.module').then(m => m.MiCuentaModule)
  },
  {
    path: 'download',
    loadChildren: () => import('../../components/download/download.module').then(m => m.DownloadModule) 
  },
];

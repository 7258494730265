import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router,
		private config:ConfigService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	private setMenu(){
		
	let	MENUITEMS: Menu[] = [
			{
				headTitle1: 'Home', headTitle2: 'Menú de opciones.',
			},
			{
				path: '/productos', title: 'Products', icon: 'shopping-bag', type: 'link'
			}
		];
		// SC CATWEB-378 agrega al menu ofertas si esta activo en configuracion.
		if(this.config.config.productos.ofertas){
			MENUITEMS.push(
				{
					path: '/ofertas-novedades/ofertas', title: 'Deals', icon: 'tag', type: 'link'
				}
			);
		}
		//SC CATWEB-409
		if(this.config.config.productos.novedades){
			MENUITEMS.push(
				{
					path: '/ofertas-novedades/novedades', title: 'News', icon: 'tag', type: 'link'
				}
			);
		}
		MENUITEMS.push(
			{
				path: '/mi-carrito/cart', title: 'My Cart', icon: 'shopping-cart', type: 'link'
			},
		);
		// SC CATWEB-378 agrega las opciones de menu comprobantes si esta activo en configuracion.
		if(this.config.config.pedido.comprobantes){
			MENUITEMS.push(
				{
					title: 'Mis Comprobantes', type: 'sub', icon: 'list', active: false, children: [
						{ path: '/Comprobantes/facturacion', title: 'Facturación', type: 'link' },
						{ path: '/Comprobantes/remito_devolucion', title: 'Remitos & Devoluciones', type: 'link' },
						{ path: '/Comprobantes/presupuestos', title: 'Presupuestos', type: 'link' },
						{ path: '/Comprobantes/recibos', title: 'Recibos de Pago', type: 'link' },
						{ path: '/Comprobantes/pedidos', title: 'My Orders', type: 'link' },
					]
				}
			);
		}else{
			// SC CATWEB-378 agrega al menu solo comprobantes de pedido si no esta activo todos los comprobantes en configuracion.
			MENUITEMS.push({
				title: 'Mis Comprobantes', type: 'sub', icon: 'list', active: false, children: [
					{ path: '/Comprobantes/pedidos', title: 'My Orders', type: 'link' },
				]
			});
		}
		// SC CATWEB-378 agrega al menu cuenta corriente si esta activo en configuracion.
		if(this.config.config.cta_cte.cta_cte_activo){
			MENUITEMS.push(
				{
					path: '/cuenta-corriente', title: 'Current Account', icon: 'dollar-sign', type: 'link'
				},
			);
		}
		// SC CATWEB-378 agrega al menu descargas si esta activo en configuracion.
		if(this.config.config.vista.descargas){
			MENUITEMS.push(
				{
					path: '/download', title: 'Download', icon: 'download', type: 'link'
				}
			);
		}
		
		return MENUITEMS;
	}

	// MENUITEMS: Menu[] = [
	// 	{
	// 		headTitle1: 'Home', headTitle2: 'Menú de opciones.',
	// 	},
	// 	{
	// 		path: '/productos', title: 'Products', icon: 'shopping-bag', type: 'link'
	// 	},
	// 	{
	// 		path: '/ofertas', title: 'Deals', icon: 'tag', type: 'link'
	// 	},
	// 	{
	// 		path: '/mi-carrito/cart', title: 'My Cart', icon: 'shopping-cart', type: 'link'
	// 	},
	// 	{
	// 		title: 'Mis Comprobantes', type: 'sub', icon: 'list', active: false, children: [
	// 			{ path: '/Comprobantes/facturacion', title: 'Facturación', type: 'link' },
	// 			{ path: '/Comprobantes/remito_devolucion', title: 'Remitos & Devoluciones', type: 'link' },
	// 			{ path: '/Comprobantes/presupuestos', title: 'Presupuestos', type: 'link' },
	// 			{ path: '/Comprobantes/recibos', title: 'Recibos de Pago', type: 'link' },
	// 			{ path: '/Comprobantes/pedidos', title: 'My Orders', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		path: '/cuenta-corriente', title: 'Current Account', icon: 'dollar-sign', type: 'link'
	// 	},
	// 	{
	// 		path: '/download', title: 'Download', icon: 'download', type: 'link'
	// 	},
	// ];

	private setMenuInvitado() {
		let MENUITEMSINVITADO: Menu[] = [
			{
				headTitle1: 'Home', headTitle2: 'Menú de opciones.',
			},
			{
				path: '/productos', title: 'Products', icon: 'shopping-bag', type: 'link'
			}
		];
		// SC CATWEB-378 agrega al menu ofertas si esta activo en configuracion.
		if(this.config.config.productos.ofertas){
			MENUITEMSINVITADO.push(
				{
					path: '/ofertas-novedades/ofertas', title: 'Deals', icon: 'tag', type: 'link'
				}
			);
		}
		//SC CATWEB-409
		if(this.config.config.productos.novedades){
			MENUITEMSINVITADO.push(
				{
					path: '/ofertas-novedades/novedades', title: 'News', icon: 'tag', type: 'link'
				}
			);
		}
		MENUITEMSINVITADO.push(
			{
				path: '/mi-carrito/cart', title: 'My Cart', icon: 'shopping-cart', type: 'link'
			},
		);

		return MENUITEMSINVITADO;
	}

	MENUITEMSADMIN: Menu[] = [
		{
			headTitle1: 'Home', headTitle2: 'Menú de opciones.',
		},
		{
			path: '/configuracion/config', title: 'Configuración', icon: 'settings', type: 'link'
		},
	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.setMenu());
	itemsInvitado = new BehaviorSubject<Menu[]>(this.setMenuInvitado());
	itemsAdmin = new BehaviorSubject<Menu[]>(this.MENUITEMSADMIN);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}


<div class="col-sm-12 col-xl-12">

          <div class="modal-header">
            <h4 class="modal-title text-muted" id="modal-basic-title">Clientes Asociados</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
            <form [formGroup]="clientForm" class="mega-inline border-style" style="display: block;" >
            <div class="mb-2">
                <div class="col-form-label text-muted">Seleccione un Cliente para Ingresar</div>
                <!-- <select
                placeholder="Select"
                class="form-control js-example-placeholder-multiple"
                formControlName="selectedClient"
              >
              <option *ngFor="let c of clients; let i = index" value= {{i}} class="text-muted">{{ c.nombre_comercial }} <p>({{c.codigo}})</p></option>
              </select> -->
              <div class="row">
                <ng-container *ngFor="let c of clients; let i = index">
                             
                <div class="col-6">
                  <div class="card">
                    <div class="media p-20">
                      <div class="radio radio-primary mr-3 ml-2">
                        <input id="{{i}}" type="radio" value={{i}} formControlName="selectedClient">
                        <label for="{{i}}"></label>
                      </div>
                      <div class="media-body">
                        <h6 class="mt-0 mega-title-badge">{{ c.nombre_comercial }}</h6>
                        <p>COD: {{c.codigo}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                </ng-container>
     
              </div>
              </div>
            </form>
              
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="setClient()" >Aceptar</button>
          </div>

  </div>

import { Component, OnInit, Renderer2 } from '@angular/core';
import { Config } from '../../../../shared/models/config.model';
import { Router } from '@angular/router';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { AuthService } from '../../../../shared/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../shared/services/config.service';
import { ValidacionesService } from 'src/app/shared/services/validaciones/validaciones.service';
import { ErrorService } from 'src/app/shared/services/error/error.service';

/*export class ConfirmPasswordValidator {
   /**
    * Valida la contraseña y la confirmacion sean iguales.
    * @param control AbstractControl
    
   static MatchPassword(control: AbstractControl) {
     const password = control.get('pass')!.value;

     const confirmPassword = control.get('retypePass')!.value;

     if (password !== confirmPassword) {
       return control.get('retypePass')!.setErrors({ ConfirmPassword: true });
     } else {
       return null;
     }
   }
 }*/

@Component({
  selector: 'app-register-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss'],
})
export class RegisterSimpleComponent implements OnInit {
  // SC CATWEB-302 Variable utilizada para mostrar / ocultar contraseña.
  public show: boolean = false;

  // SC CATWEB-302 RegExp (expresion regular) para validar email.
  
  // private numDocPattern: any = /^(\b(20|23|24|25|26|27|30|33|34)(\-)\d{8}(\-)\d{1})|(\d{2}(\.)\d{3}(\.)\d{3})$/;
  // private fechaPattern: any = /^(([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4}))$/;

  // SC CATWEB-302 variable para la configuracion general del catalogo.
  public config: Config = {};
 
  // SC CATWEB-302 Definiciom y validacion de los campos del formulario de registro de usuario de clientes.
  public formRegister: FormGroup = this.fb.group(
    {
      codigo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(5),
        ]),
      ],
      usuario: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(5),
        ]),
      ],
      pass: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(5),
        ]),
      ],
      retypePass: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(5),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(this.validaciones.emailPattern),
        ]),
      ],
      recaptcha: ['', Validators.required],
    },
    {
      validator: this.validaciones.matchPassword("pass", "retypePass"),
    }
  );

  // show_button: Boolean = false;
  // show_eye: Boolean = false;
  // ckeckPass: Boolean = false;
  // SC CATWEB-302 clave para captcha.
  siteKey: string = '';
  // SC CATWEB-302 arreglo de suscripciones para los observables.
  private unsubscribe: Subscription[] = [];

  constructor(
    public configService: ConfigService,
    //private renderer: Renderer2,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public validaciones: ValidacionesService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    // SC CATWEB-302 Inicia las variables de configuracion general del catalogo, la clave para captcha y la imagen de la empresa.
    this.config = this.configService.config;
    this.siteKey = this.config.google.key_site_google;

    // SC CATWEB-302 Observa los cambios en el camppo pass eliminando los espacios que se ingresan.
    const suscPass = this.formRegister.controls.pass.valueChanges.subscribe(
      (newValue) => {
        const trimmed = this.eliminarEspacios(newValue);
        this.formRegister.controls.pass.setValue(trimmed, { emitEvent: false });
      }
    );

    // this.formRegister.markAllAsTouched(); marca todos
    this.unsubscribe.push(suscPass);
    // SC CATWEB-302 Observa los cambios en el camppo reply pass eliminando los espacios que se ingresan.
    const suscReply =
      this.formRegister.controls.retypePass.valueChanges.subscribe(
        (newValue) => {
          const trimmed = this.eliminarEspacios(newValue);
          this.formRegister.controls.retypePass.setValue(trimmed, {
            emitEvent: false,
          });
        }
      );
    this.unsubscribe.push(suscReply);
  }

  // SC CATWEB-302 Funcion realiza el registro de usuario.
  register() {
    
    const { codigo, usuario, pass, email } = this.formRegister.value;
    const registerSub = this.authService
      .register(codigo, usuario, pass, email)
      .subscribe((resp) => {
        
        if (resp > 0) {
          this.toastr.success(`Usuario se registro con éxito`);
          this.router.navigateByUrl('/auth/login');
        } else {
          
          this.toastr.error(`${resp.Error}`);
        }
      },
      (error: any) => {
        this.errorService.getError(error.status);
      });
    this.unsubscribe.push(registerSub);
  }
  // SC CATWEB-302 Funcion dado un campo retorna si es valido.
  isControlValid(controlName: string): boolean {
    const control = this.formRegister.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  // SC CATWEB-302 Funcion dado un campo retorna si no es valido.
  isControlInvalid(controlName: string): boolean {
    const control = this.formRegister.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  // SC CATWEB-302 Funcion dado un campo y la regla de validacion verifica si tiene errores
  controlHasError(validation: string, controlName: string): boolean {
    const control = this.formRegister.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  // SC CATWEB-302 Verifica si el campo fue instanciado.
  isControlTouched(controlName: string): boolean {
    const control = this.formRegister.controls[controlName];
    return control.dirty || control.touched;
  }

  // SC CATWEB-302 Valida si las contraseñas son iguales.
  validPass() {
    //console.log(this.formRegister.controls.pass.value);
    return (
      this.formRegister.controls.pass.value ==
      this.formRegister.controls.retypePass.value
    );
  }

  // SC CATWEB-302 Funcion Muestra/Oculta la contraseña.
  showPassword() {
    this.show = !this.show;
  }

  // SC CATWEB-302 Funcion elimina los espacios en blanco.
  // replace(/\s+/g, "") remplaza los espacion en blanco del string.
  // trim() Elimina los espacios de los extremos.
  eliminarEspacios(newValue: string) {
    return newValue.replace(/\s+/g, '').trim();
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from 'src/app/shared/models/config.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProvinciaService } from 'src/app/shared/services/provincia/provincia.service';
import { NuevoUsuario } from "src/app/shared/models/auth/nuevo-usuario.model";
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ValidacionesService } from 'src/app/shared/services/validaciones/validaciones.service';
import { EmailsService } from 'src/app/shared/services/emails/emails.service';
import { ErrorService } from 'src/app/shared/services/error/error.service';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.scss']
})
export class NuevoUsuarioComponent implements OnInit {

  // MV CATWEB-321 Variable utilizada para mostrar / ocultar contraseña.
  public show: boolean = false;
  // MV CATWEB-321 variable para la configuracion general del catalogo.
  public config: Config = {};

  // MV CATWEB-321 clave para captcha.
  siteKey: string = '';

  // MV CATWEB-321 Definicion y validacion de los campos del formulario de registro de nuevos usuarios web no registrados en sinergial.
  public formRegister: FormGroup = this.fb.group({
    cliente: this.fb.group({
      nombre: ['', [Validators.required, Validators.maxLength(30)]],
      apellido: ['', [Validators.required, Validators.maxLength(20)]],
      calle: ['', [Validators.required, Validators.maxLength(30)]],
      numero: ['', [Validators.required, Validators.maxLength(5)]],
      barrio: [''],
      torre: [''],
      piso: [''],
      departamento: [''],
      id_localidad: [, [Validators.required]],
      id_tipo_doc: [, [Validators.required]],
      numero_doc: ['', [Validators.required]],
      id_respiva: [, [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.validaciones.emailPattern)]],
      sexo: ['masculino', [Validators.required]],
      fecha_nacimiento: ['', [Validators.required]]
    }),
    usuario_web: this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.validaciones.emailPattern)]],
      usuario: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]],
      clave: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(5)]],
      retypeClave: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(5)]],
      recaptcha: ['', Validators.required]
    })
  },
  {
    validators: [this.validaciones.matchPassword("usuario_web.clave", "usuario_web.retypeClave"), 
                  this.validaciones.mayorDeEdad("cliente.fecha_nacimiento")]
  });

  provincia = new FormControl(0);

  // MV CATWEB-321 Variable que almacena las provincias
  public provincias: any[] = [];
  // MV CATWEB-321 Variable que almacena las localidades
  public localidades: any[] = [];

  constructor(public configService: ConfigService,
              private fb: FormBuilder,
              private provinciaService: ProvinciaService,
              private authService: AuthService,
              private toastr: ToastrService,
              private router: Router,
              public validaciones: ValidacionesService,
              private emailsService: EmailsService,
              private errorService: ErrorService) { }

  ngOnInit(): void {

    // MV CATWEB-321 Inicia las variables de configuracion general del catalogo, la clave para captcha y la imagen de la empresa.
    this.config = this.configService.config;
    this.siteKey = this.config.google.key_site_google;

    // MV CATWEB-321 Inicializa el arreglo de provincias cuando carga el componente
    const subProvincias = this.provinciaService.getProvincias().subscribe(resp => {
      this.provincias = resp.slice(1);
    });

    // MV CATWEB-321 Se cargan las localidades cuando cambia la provincia seleccionada
    const subCambiaProvincia = this.provincia.valueChanges.subscribe(resp => {
      this.provinciaService.getLocalidades(resp).subscribe(localidades => {
        this.localidades = localidades;
      })
    });
  }

  // MV CATWEB-321 Funcion que realiza el registro del nuevo usuario y envia el correo con los datos de su cuenta.
  register() {
    const usuarioNuevo: NuevoUsuario = this.formRegister.value;
    usuarioNuevo.cliente.fecha_nacimiento = this.formatearFecha(this.formRegister.get('cliente.fecha_nacimiento').value);
    usuarioNuevo.usuario_web.id_rol_usr_web = 1;
    this.authService.registerNuevoUsuario(usuarioNuevo)
      .subscribe(resp => {
        console.log(resp);
        if(resp.id_cliente > 0){
          const bodyEmail = {
            type:"register",
            data:{
              codigo_cliente: resp.codigo_cliente,
              nombre_cliente: resp.nombre_cliente,
              email: resp.email,
              usuario_web: resp.usuario_web,
              codigo_cupon: "",
              valor_cupon: 0
            }
          }
          this.emailsService.enviarEmail(bodyEmail).subscribe(() => {
            this.toastr.success(`Usuario se registro con éxito`);
            this.router.navigateByUrl('/auth/login');
          })
        } else {
          this.toastr.error(`${resp.Error}`);
        }
      },
      (error: any) => {
        this.errorService.getError(error.status);
      });
  }

  // MV CATWEB-321 Funcion Muestra/Oculta la contraseña.
  showPassword() {
    this.show = !this.show;
  }

  // MV CATWEB-321 Funcion para formatear fecha en el formato a enviar correcto
  formatearFecha(fecha: string) {
    const datosFecha = fecha.split('-');
    return `${datosFecha[2]}/${datosFecha[1]}/${datosFecha[0]}`
  }

}

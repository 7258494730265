import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { AuthService } from '../auth/auth.service';
import { SucursalModel } from '../../models/sucursal/sucursal.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  private baseUrl = this.configService.config.servidor?.api_server;
  private accessToken: string = environment.AccessToken;

  // SC CATWEB-410 se agregan variables observables para las sucursales del cliente dado que sera posible modificar.
  private sucursalesIds = new BehaviorSubject<Array<SucursalModel>>([]); //Definimos nuestro BehaviorSubject, este debe tener un valor inicial siempre
  public currentDataSuc$ = this.sucursalesIds.asObservable(); //Tenemos un observable con el valor actual del BehaviourSubject
  private sucursalSelect = new BehaviorSubject<SucursalModel>(null);
  public currentDataSucSelect$ = this.sucursalSelect.asObservable();
  
  constructor(private http: HttpClient, private configService: ConfigService, private authService: AuthService) { }

  getSucursal(){
    const url = `${this.baseUrl}/Sucursal`;
    let params = {};
    {Object.assign(params, {Access_Token: this.accessToken, Plataforma: 2});}
    // console.log( params);
    return this.http.get<[SucursalModel]>(url, {params: params});
  }

   // SC CATWEB-410 Se obtienen arreglo de las sucursales asociada al cliente en Sinergial
  getSucursalesByIds(ids:string){
    const url = `${this.baseUrl}/Sucursal`;
    let params = {};
    {Object.assign(params, {Access_Token: this.accessToken, Plataforma: 2, ids_sucursales:ids});}
    // console.log( params);
    const susc = this.http.get<[SucursalModel]>(url, {params: params}).subscribe((resp) => {
      this.sucursalesIds.next(resp);
      if(resp.length > 0){
        this.sucursalSelect.next(resp[0]);
      }
      
    });
    susc.unsubscribe;
  }

   // SC CATWEB-410 setea la sucursal seleccionada necesaria para realizar consulta de los productos y realizar pedido. 
  setSucursalSelect(index:number){
    let suc = this.sucursalesIds.getValue();
    this.sucursalSelect.next(suc[index]);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/shared/services/config.service';
import { Config } from '../../../shared/models/config.model';
import { ValidacionesService } from "../../../shared/services/validaciones/validaciones.service";
import { EmailsService } from "../../../shared/services/emails/emails.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RecuperarPassModel } from 'src/app/shared/models/emails/recuperarPass.model';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  // MV CATWEB-322 variable para la configuracion general del catalogo.
  public config: Config = {};

  // MV CATWEB-322 clave para captcha.
  siteKey: string = '';

  public formRestablecerPass: FormGroup = this.fb.group({
    codigo: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(5)]],
    usuario: ['', [Validators.required, Validators.email, Validators.pattern(this.validaciones.emailPattern)]],
    recaptcha: ['', Validators.required]
  });

  constructor(public configService: ConfigService,
    private fb: FormBuilder,
    public validaciones: ValidacionesService,
    private emailService: EmailsService,
    private toastr: ToastrService,
    private router: Router,) { }

  ngOnInit() {
    // MV CATWEB-322 Inicia las variables de configuracion general del catalogo, la clave para captcha y la imagen de la empresa.
    this.config = this.configService.config;
    this.siteKey = this.config.google.key_site_google;
  }

  // MV CATWEB-322 Funcion para reestablecer la contraseña y enviar el correo correspondiente al usuario
  enviarCorreo() {
    const { usuario, codigo } = this.formRestablecerPass.value;

    this.emailService.recuperarPass({ usuario, codigo }).
      subscribe(resp => {
        if (resp.id_usuario_web) {
          const bodyEmail = {
            type:"recover",
            data:{
              email: resp.email,
              usuario: resp.usuario,
              clave: resp.clave
            }
          }
          this.emailService.enviarEmail(bodyEmail).subscribe(() => {
            this.toastr.success(`Se le ha enviado un correo con la nueva contraseña. Por favor, verifique su casilla de correo`);
            this.router.navigateByUrl('/auth/login');
          });
        }
        else {
          this.toastr.error(`${resp.Error}`);
        }
      }
      )
  }

}

<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/favicon.png" alt="looginpage" />
                <h1>{{config.cliente!.titulo_page}}</h1>
              </a>
            </div>
            <div class="login-main">
              <!-- MV CATWEB-322 Formulario para restablecer la constraseña -->
              <form class="theme-form" [formGroup]="formRestablecerPass" (ngSubmit)="enviarCorreo()">
                <h4>Restablece tu contraseña</h4>
                <p>Ingresá tu número de cliente y correo electrónico. Te enviaremos las instrucciones para restablecer
                  tu contraseña.</p>

                <!-- MV CATWEB-322 Codigo cliente -->
                <div class="form-group">
                  <label class="col-form-label pt-0">Código Cliente</label>

                  <input type="text" class="form-control" placeholder="Código cliente" formControlName="codigo"
                    [class.is-invalid]="validaciones.isControlInvalid(formRestablecerPass, 'codigo')" [class.is-valid]="validaciones.isControlValid(formRestablecerPass, 'codigo')"
                    mask="9{10}" />
                  <!-- MV CATWEB-322 Validaciones Código-->
                  <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRestablecerPass,'codigo', 'required')">
                    Código requerido
                  </div>
                  <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRestablecerPass, 'codigo', 'maxlength')">
                    Código debe tener menos de 10 digitos
                  </div>
                  <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRestablecerPass, 'codigo', 'minlength')">
                    Código debe tener mas de 5 digitos
                  </div>
                </div>

                <!-- MV CATWEB-322 Email -->
                <div class="form-group">
                  <label class="col-form-label">Email</label>
                  <input type="email" class="form-control" placeholder="Email" formControlName="usuario"
                    [class.is-invalid]="validaciones.isControlInvalid(formRestablecerPass, 'usuario')" [class.is-valid]="validaciones.isControlValid(formRestablecerPass, 'usuario')" />
                  <!-- MV CATWEB-322 Validaciones email -->
                  <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRestablecerPass, 'usuario', 'required')">
                    Email requerido
                  </div>
                  <div class="invalid-feedback" *ngIf="validaciones.controlHasError(formRestablecerPass, 'usuario', 'pattern')">
                    Email no válido
                  </div>
                </div>

                <!-- MV CATWEB-322 Recaptcha -->
                <div class="form-group mb-0">
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                  </ngx-recaptcha2>
                  <br />
                  <button class="btn btn-primary btn-block" type="submit" [disabled]="formRestablecerPass.invalid">
                    Restablecer Contraseña
                  </button>
                </div>
                <p class="mt-4 mb-0">
                  Tienes una cuenta?<a class="ml-2" [routerLink]="'/auth/login'"
                    >Ingresar</a
                  >
                </p>

                <!-- MV CATWEB-322 Formulario para restablecer la constraseña END-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
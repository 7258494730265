<div class="media profile-media" (click)="toggleNotificationMobile()">
    <!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="" /> -->
    <span>Sucursal</span>
    <div class="media-body">
        <!-- <span>rio cuarto</span> -->
      <p class="mb-0 font-roboto">
        
        <i class="middle fa fa-angle-down"></i>
      </p>
    </div>
  </div>
  <!-- <ul class="profile-dropdown onhover-show-div" [class.active]="openNotification">
    <li>
      <a href="javascript:void(0)"
        ><span>hola</span></a
      >
    </li>
  </ul> -->
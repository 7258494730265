import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService) {
    if(this.authService.usuario.invitado){
      this.authService.mostrarLogin();
    }
    this.authService.logout();
  }
  

  ngOnInit(): void {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Config } from '../../shared/models/config.model';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/auth/auth.service';
import { ErrorService } from '../../shared/services/error/error.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { AuthModel } from 'src/app/shared/models/auth/auth.model';
import { UserModel } from 'src/app/shared/models/auth/user.model';
import { environment } from 'src/environments/environment';
import { ModalClientComponent } from './modal-client/modal-client.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { parameterProviderFactory } from 'src/app/app.module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public usuariosGuardados: string[];
  public config: Config;
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  private unsubscribe: Subscription[] = [];
  private accessToken: string = environment.AccessToken;
  // SC CATWEB-343 28/09/2021 Variable identifica si el usuario invitado esta activo.
  public invitado: boolean = false;
  public registro: boolean = false;
  private usuario: UserModel;

  public tituloLogin: string;
  public textoLogin: string;

  public logintheme: number = 1;
  constructor(
    private configService: ConfigService,
    public authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private errorService: ErrorService
  ) {
    // SC CATWEB-324 inicializacion y definicion de validacion para los campos del formulario de loguin.
    this.loginForm = this.fb.group({
      usuario: ['', [Validators.required, Validators.maxLength(25)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(16),
        ],
      ],
      recordarme: [true],
    });
  }

  ngOnInit() {
    this.config = this.configService.config;
    this.logintheme = this.config.vista.login;

    // SC CATWEB-343 28/09/2021 setea si esta activo el ingreso usuario invitado.
    this.invitado = this.config.sesion.invitado.active;


    if (!this.authService.muestraLogin) {
      this.loginInvitado();
    }

    this.registro = this.config.sesion.registro;
    // MV CATWEB-322 Se cargan los usuarios guardados para sugerirlos
    this.cargarUsuariosRecordados();

    this.tituloLogin = this.configService.config.cliente.titulo_login;
    this.textoLogin = this.configService.config.cliente.texto_login;

    
  }

  showPassword() {
    this.show = !this.show;
  }

  // SC CATWEB-324 logion de usuario api 2021.1.2.0
  login() {
    const { usuario, password } = this.loginForm.value;

    const loginSub = this.authService
      .login(usuario, password)
      .subscribe((resp) => {
        //console.log(resp);
        if (resp! > 0) {
          this.router.navigateByUrl('/home');
        } else {
          this.toastr.error(`${resp}`);
        }
      });
    this.unsubscribe.push(loginSub);
  }
  // SC CATWEB-332 Nuevo login de usuario, api 2021.2.1.0, que inicia sesion segun reciba un arreglo de cliente vacio, con un solo cliente o con una lista de ellos.
  login2($event) {
    
    const { usuario, password } = $event;
    const loginSub = this.authService
      .login2(usuario, password)
      .subscribe((resp) => {
        if (resp.id_usuario_web) {
          //MV CATWEB-322 Si el login es exitoso, se recuerda al usuario en caso de que quiera recordarse
          this.recordarUsuario(usuario);

          let cantClient = resp.clientes.length;
          if (cantClient == 0) {
            // SC CATWEB-332 Mensaje de error cuando no hay clientes asociados.
            this.toastr.error(`Usuario sin cliente asociado`);
          } else if (cantClient == 1) {
            
            if (this.checkSucursal(resp)) {
           
              // SC CATWEB-332 caso que solo tenga un cliente setean los datos e inicia sesion.
              this.usuario = this.setUserClient(resp);
              this.authService.setAuthFromLocalStorage(this.usuario);
              // SC CATWEB-343 28/09/2021 redirecciona a la pagina de inicio, por configuracion, si es invitado.
              if (this.invitado) {
                this.pageRedirect(
                  this.config.sesion.invitado.page_inicio_invitado
                );
              } else {
                this.pageRedirect(this.config.sesion.page_inicio_cliente);
              }
            }
          } else {
            // SC CATWEB-332 Se abre modal con el arreglo de clientes para ser seleccionados.
            const modalRef = this.modalService.open(ModalClientComponent, {
              size: 'lg',
              centered: true,
            });
            modalRef.componentInstance.clients = resp.clientes;
            modalRef.result.then((result) => {
              // SC CATWEB-332 obtiene cliente, resultado de la seleccion, y procede a inicia sesion.
              resp.clientes = [result];

              if (this.checkSucursal(resp)) {
              this.usuario = this.setUserClient(resp);

              this.authService.setAuthFromLocalStorage(this.usuario);

              this.pageRedirect(this.config.sesion.page_inicio_cliente);
              }
            });
          }
        } else {
          let status = resp.status;
          if (status == 400 && resp.error.Error) {
            // SC CATWEB-332 Mensaje de error retorna la API, datos de usuario incorrectos.
            this.toastr.error(`${resp.error.Error}`);
          } else {
            this.errorService.getError(status);
          }
        }
      }, (err) =>{
        this.toastr.error(err.message);
      });

    this.unsubscribe.push(loginSub);
  }

  // SC CATWEB-332 Se setean los datos del cliente seleccionado.
  private setUserClient(user: AuthModel) {
    let id_sucursal = user.modo_por_sucursal ? user.clientes[0].ids_sucursales : "-1";
    // SC CATWEB-343 28/09/2021 Se define si el usuario logueado es o no invitado.
    this.invitado =
      this.invitado && user.usuario == this.config.sesion.invitado.user_invitado
        ? true
        : false;

    return {
      id_cliente: user.clientes[0].id_cliente,
      codigo: user.clientes[0].codigo,
      nombre: user.clientes[0].nombre,
      nombre_comercial: user.clientes[0].nombre_comercial!,
      email: user.clientes[0].email!,
      nrodoc: user.clientes[0].nrodoc!,
      direccion: user.clientes[0].direccion!,
      precioventausado: user.clientes[0].precioventausado,
      listaprecio: user.clientes[0].listaprecio!,
      usuario: user.usuario!,
      email_usuario_web: user.email_usuario_web!,
      id_usuario_web: user.id_usuario_web!,
      observacion: user.observacion,
      invitado: this.invitado, // SC CATWEB-343 28/09/2021 Se agrega el valor de invitado a los datos del usuario logueado, true es cliente ocasional, false es cliente.
      token: this.accessToken,
      expire_token:
        this.config.sesion.session_times > 0
          ? Date.now() + this.config.sesion.session_times * 1000
          : 0,
      ids_sucursales: id_sucursal,
      modo_por_sucursal:user.modo_por_sucursal
    };
  }

  // SC CATWEB-343 28/09/2021 Funcion click boton de ingreso como invitado.
  loginInvitado() {
    this.loginForm.setValue({
      usuario: this.config.sesion.invitado.user_invitado,
      password: this.config.sesion.invitado.pass_invitado,
      recordarme: false,
    });

    this.login2(this.loginForm.value);
  }

  // SC CATWEB-343 28/09/2021 Funcion redirecciona a la pagina segun la page que se pasa por parametro.
  pageRedirect(page: string) {
    switch (page) {
      case 'H':
        this.router.navigateByUrl('/home');
        break;
      case 'P':
        this.router.navigateByUrl('/productos');
        break;
      case 'O':
        this.router.navigateByUrl('/ofertas');
        break;
      default:
        break;
    }
  }

  //MV CATWEB-322 Funcion para agregar usuario a usuariosGuardados si selecciona Recordarme y setear usuariosGuardados en localStorage
  private recordarUsuario(usuario: string) {
    if (
      this.loginForm.get('recordarme').value &&
      !this.usuariosGuardados.includes(usuario)
    ) {
      this.usuariosGuardados.push(usuario);
      localStorage.setItem('usuarios', JSON.stringify(this.usuariosGuardados));
    }
  }

  //MV CATWEB-322 Funcion para cargar los usuarios guardados en localStorage y almacenarlos en usuariosGuardados.
  private cargarUsuariosRecordados() {
    if (localStorage.getItem('usuarios') !== null) {
      this.usuariosGuardados = JSON.parse(localStorage.getItem('usuarios'));
    } else {
      this.usuariosGuardados = [];
    }
  }

  private checkSucursal(user: AuthModel) {
    let result = false;
    let id_suc = user.clientes[0].ids_sucursales;
    let checkSuc =
      user.modo_por_sucursal && (typeof id_suc != 'string' || id_suc == '');
    if (checkSuc) {
      this.toastr.error('No tiene sucursal asociada');
    }else{
      result = true;
    }
    return result;
  }
}

<!-- footer start-->

<div class="container-fluid">
  <ng-container *ngIf="footer.icon_whatsapp.active">
    <div id="wasaprow" class="row">
      <!-- <a href="https://api.whatsapp.com/send?phone=+5493584701936"><img src="./assets/images/logo/logo-WA.png"></a> -->
      <a href="{{footer.icon_whatsapp.link}}" target="_blank"><img src="./assets/images/logo/logo-WA.png"></a> 
    </div>
  </ng-container>
  <div class="text-center">
    <div class="row">
      <ng-container *ngIf="footer.footer">
        <ng-container *ngFor="let item of footer.items; let i=index">
          <div class="col-md-4">
            
            <h6>{{item.tittle}}</h6>
            <ul>
              <li *ngFor="let info of item.info; let i=index" class="submenu-title ng-star-inserted">
                <ng-container *ngIf="info.link!=''; else elseText">
                  <a href="{{info.link}}" target="_blank"><span><i class="{{ info.icon != '' ? info.icon : ''}}"></i>{{info.descripcion}}</span></a>
                  
                </ng-container>
                <ng-template #elseText>
                  <span><i class="{{ info.icon != '' ? info.icon : ''}}"></i>
                  {{info.descripcion}}</span>
                </ng-template>
                
                </li>
            </ul>
          </div>
         
        </ng-container>
      </ng-container>
    
    </div>
</div>
<br/>
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright © 2019 - {{ today | date:'y'}} <a href="https://www.oliverinformatica.com/" target="_blank"> Oliver</a> {{version}}</p>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigService } from '../../../shared/services/config.service';
import { environment } from '../../../../environments/environment';
import { catchError, map} from 'rxjs/operators';
import { RecuperarPassModel } from "../../models/emails/recuperarPass.model";
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  private baseUrl = this.configService.config.servidor?.api_server;
  private emailsUrl = this.configService.config.servidor.api_cart;
  private accessToken: string = environment.AccessToken;

  constructor(private http: HttpClient,
              public configService: ConfigService) { }

  recuperarPass(datosUsuario: RecuperarPassModel){
    const url = `${this.baseUrl}/Login`;
    const body = datosUsuario;
    const params = new HttpParams().set('Access_Token',this.accessToken);

    return this.http.put<any>(url, body, {params: params});
  }

  enviarEmail(datosEmail){
    const url = `${this.emailsUrl}/mail`;
    const body = {...datosEmail};

    return this.http.post<any>(url, body);

  }
}

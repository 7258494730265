import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../../services/carrito/carrito.service';
import { CarritoModel } from '../../../../models/carrito/carrito.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import es from '@angular/common/locales/es';
import { ConfigService } from 'src/app/shared/services/config.service';
import { Router } from '@angular/router';

declare var require;
const Swal = require('sweetalert2');
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public openCart: boolean = false;
  public cart: CarritoModel[] = [];
  public totalQuantity = 0;
  public totalPrice = 0;
  private id_cliente = this.authService.usuario.id_cliente;
  public controlStock:boolean = this.config.config.productos.control_stock;
  public verificaStock:boolean = false;
   //SC CATWEB-233 variables para informar modificaciones
   public modificado:string = "Precio actualizado";
   public noDisponible:string = "Stock no disponible";
   public sinStock:string = "Sin Stock";

  constructor(
    private carritoServicve: CarritoService,
    private authService: AuthService,
    private toast: ToastrService,
    private config: ConfigService,
    private router: Router

  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.carritoServicve.getCart(this.id_cliente);
    this.carritoServicve.currentDataCart$.subscribe((resp) => {
      //console.log(resp);
      if (resp) {
       // this.carritoServicve.updatePriceStock(resp);
        this.cart = resp;
        this.totalQuantity = resp.length;
        this.totalPrice = resp.reduce(
          (sum, current) => sum + current.precio * current.cantidad,
          0
        );
        // this.carritoServicve.validStockCart();
      }
    });
    // this.carritoServicve.currentValidCart$.subscribe((resp) => {
    //   this.verificaStock = resp;
    //   console.log(this.verificaStock);
    // });

    if(!this.authService.usuario.invitado){
      this.controlCartInv();
    }
    
  }

  // For Mobile Device
  toggleCart() {
    this.openCart = !this.openCart;
  }

  public increment(qty) {
    let quantity = <HTMLInputElement>document.getElementById(`qty${qty}`);
    quantity.value = (parseFloat(quantity.value) + 1).toString();
  }

  public decrement(qty) {
    let quantity = <HTMLInputElement>document.getElementById(`qty${qty}`);
    let quantityValue = parseFloat(quantity.value);
    if (quantityValue > 1) {
      quantity.value = (quantityValue - 1).toString();
    }
  }

  update(product) {
    let quantity = <HTMLInputElement>(
      document.getElementById(`qty${product.id_producto}`)
    );
    let quantityValue = parseFloat(quantity.value);
    if (quantityValue > 0) {
      if (this.carritoServicve.validarStock(quantityValue, product.stock)) {
        let item: CarritoModel;
        item = Object.assign({}, product);
        item.cantidad = quantityValue;

        if (this.carritoServicve.changeCart(item, false)) {
          this.toast.success(
            `${product.nombre} ` + CarritoService.MSG_UPDATE_CART,
            '',
            {
              timeOut: 2000,
            }
          );
        }
      } else {
        this.toast.error(CarritoService.MSG_ERROR_STOCK);
        quantity.value = product.cantidad;
      }
    }
    // this.toast.info(`Se ha actualizado la cantidad de ${product.nombre}`, '', {
    // timeOut: 2000,
    // });
  }

  delete(producto) {
    console.log(this.carritoServicve.delCart(this.id_cliente, producto.id_producto));
    if(this.carritoServicve.delCart(this.id_cliente, producto.id_producto)){
      console.log("delete");
      this.toast.error(`${producto.nombre} `+ CarritoService.MSG_DELETE_CART, '', {
        timeOut: 2000,
      });
    }
    
  }

  finalizar(){
    // if(this.controlStock){
    //   this.carritoServicve.updatePriceStock(this.cart);
    //   // if(!this.carritoServicve.validPriceCart()){
    //   //   console.log('valido precios');
    //   //   this.toast.info('Se actalizaron precios en carrito');
    //   // }
    //   if(this.carritoServicve.validStockCart()){
    //     this.router.navigateByUrl('mi-carrito/checkout');
    //   }else{
    //     this.toast.error('Verifiqué stock de los productos en carrito');
    //   }
      
    // }else{
      this.router.navigateByUrl('mi-carrito/checkout');
    // }
  }

  controlCartInv() {
    let cartInv = this.carritoServicve.getCartInv();
    if (cartInv.length > 0) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Actualizar Carrito',
          text: 'Se agregaron productos al carrito, deseas actualizarlo?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, Actualizar',
          cancelButtonText: 'No, Cancelar',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            // swalWithBootstrapButtons.fire(
            //   'Deleted!',
            //   'Your file has been deleted.',
            //   'success'
            // )
            this.carritoServicve.cartInvtoCartClient(cartInv);
            this.toast.success('Su carrito ha sido actualizado.');
            swalWithBootstrapButtons.close();
          } else if (
            // Read more about handling dismissals
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.carritoServicve.emptyCartInv();
            swalWithBootstrapButtons.close();
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Your imaginary file is safe :)',
            //   'error'
            // )
          }
        });
    }
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { environment } from '../../../../environments/environment';
import { CarritoModel } from '.././../models/carrito/carrito.model';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from '../producto/producto.service';
import { SucursalService } from '../sucursal/sucursal.service';
import { ParamsProductModel } from '../../models/producto/params-product.model';
const MSG_ERROR_STOCK: string = 'Stock no disponible';
const MSG_UPDATE_CART: string = 'se ha actualizado';
const MSG_DELETE_CART: string = 'se ha eliminado';
const MSG_ADD_CART: string = 'se ha agregado al carrito';

@Injectable({
  providedIn: 'root',
})
export class CarritoService {
  private baseUrl = this.configService.config.servidor?.api_cart;
  private accessToken: string = environment.AccessToken;
  public cartLocalStorage = `${environment.cartKey}`;
  // public cartLocalStorage = `inv-${environment.cartKey}`;
  get usuario() {
    return this.authService.usuario;
  }

  private cart = new BehaviorSubject<Array<CarritoModel>>(null); //Definimos nuestro BehaviorSubject, este debe tener un valor inicial siempre
  public currentDataCart$ = this.cart.asObservable(); //Tenemos un observable con el valor actual del BehaviourSubject

  private validCart = new BehaviorSubject<boolean>(false);
  public currentValidCart$ = this.validCart.asObservable();

  static MSG_ERROR_STOCK: string = 'Stock no disponible.';
  static MSG_UPDATE_CART: string = 'se ha actualizado.';
  static MSG_DELETE_CART: string = 'se ha eliminado.';
  static MSG_ADD_CART: string = 'se ha agregado al carrito.';
  static MSG_ERROR_CANT_MIN: string = 'Cantidad no válida.';
  static MSG_ERROR_CART: string = 'No podés agregar este producto.';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
    private productoService: ProductoService,
    private sucursalService: SucursalService
  ) {}

  getCart(id_cliente: number) {
    // SC CATWEB-335 su el usuario logueado es invitado carga el carrito de memoria caso contrario obtiene el carrito del cliente.
    if (this.authService.usuario.invitado) {
      this.updatePriceStock(this.getCartInv()); // SC CATWEB-403 Se actualiza el carrito de invitado realizando consulta de los productos en sinergial.
      this.cart.next(this.getCartInv());
    } else {
      const url = `${this.baseUrl}/carrito`;
      //const params = new HttpParams().set('id_cliente',id_cliente);
      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: {
          id_cliente: id_cliente,
        },
      };
      this.http.get<CarritoModel[]>(url, options).subscribe((resp) => {
        
        this.updatePriceStock(resp);// SC CATWEB-403 Se actualiza el carrito realizando consulta de los productos en sinergial.
        
        this.cart.next(resp);
        // SC CATWEB-335 Si hay productos carggados en carrito invitado se actualiza en carrito clearInterval.
        // let cartInv = [];
        // cartInv = this.getCartInv();
        // if (cartInv.length > 0) {
        //   this.cartInvtoCartClient(cartInv);
        // }
      });
    }
  }

  public changeCart(item: CarritoModel, producto: boolean) {
    //Obtenemos el valor actual
    let cart = this.cart.getValue();
    console.log(cart);
    //Si no es el primer item del carrito
    let result = false;      
    
    if (cart) {
      //Buscamos si ya cargamos ese item en el carrito
      let objIndex = cart.findIndex(
        (obj) => obj.id_producto == item.id_producto
      );
      //Si ya cargamos uno aumentamos su cantidad
      if (objIndex != -1) {
        if (producto) {
          item.cantidad =
            parseFloat(cart[objIndex].cantidad) + parseFloat(item.cantidad);
        }

        if (this.validarStock(item.cantidad, item.stock)) {
          // SC CATWEB-335 si el usuario es invitado actualiza el carrito de memoria.
          if (this.authService.usuario.invitado) {
            cart[objIndex].cantidad = item.cantidad;
            this.updateCartInnv(cart);

            result = true;
          } else {
            if (this.updateCart(item)) {
              cart[objIndex].cantidad = item.cantidad;

              result = true;
            }
          }
        }
      }
      //Si es el primer item de ese tipo lo agregamos derecho al carrito
      else {
        // if (this.validarStock(item.cantidad, item.stock)) {
        // SC CATWEB-335 si el usuario es invitado actualiza el carrito de memoria.
        if (this.authService.usuario.invitado) {
          cart.push(item);
          this.updateCartInnv(cart);
          result = true;
        } else {
          if (this.addCart(item)) {
            cart.push(item);
            result = true;
          }
        }
        // }
      }
    }
    //Si es el primer elemento lo inicializamos
    else {
      cart = [];
      // if (this.validarStock(item.cantidad, item.stock)) {
      // SC CATWEB-335 si el usuario es invitado actualiza el carrito de memoria.
      if (this.authService.usuario.invitado) {
        cart.push(item);
        this.updateCartInnv(cart);
        result = true;
      } else {
        if (this.addCart(item)) {
          cart.push(item);
          result = true;
        }
        // }
      }
    }
    if (result) {
      this.cart.next(cart); //Enviamos el valor a todos los Observers que estan escuchando nuestro Observable
    }
    return result;
  
  }

  addCart(body: CarritoModel) {
    const url = `${this.baseUrl}/carrito`;
    //const params = new HttpParams().set('Access_Token',this.accessToken);
    return this.http.post<boolean>(url, body).subscribe((resp) => {
      if (resp) {
        return resp;
      } else {
        return false;
      }
    });
  }

  updateCart(body: CarritoModel) {
    const url = `${this.baseUrl}/carrito`;
    //const params = new HttpParams().set('Access_Token',this.accessToken);
    return this.http.put<boolean>(url, body).subscribe((resp) => {
      if (resp) {
        return resp;
      } else {
        return false;
      }
    });
  }

  async delCart(id_cliente: number, id_producto: number) {
    if (this.authService.usuario.invitado) {
      //Obtenemos el valor actual
      let cart = this.cart.getValue();
      //Buscamos si ya cargamos ese item en el carrito
      let objIndex = cart.findIndex((obj) => obj.id_producto == id_producto);
      if (objIndex != -1) {
        cart.splice(objIndex, 1);
        this.updateCartInnv(cart);
        this.cart.next(cart);
        return true;
      }
    } else {
      const url = `${this.baseUrl}/carrito`;
      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: {
          id_cliente: id_cliente,
          id_producto: id_producto,
        },
      };
      //const params = new HttpParams().set('Access_Token',this.accessToken);
      this.http.delete<boolean>(url, options).subscribe((resp) => {
        // console.log(resp);
        if (resp) {
          //Obtenemos el valor actual
          let cart = this.cart.getValue();
          //Buscamos si ya cargamos ese item en el carrito
          let objIndex = cart.findIndex(
            (obj) => obj.id_producto == id_producto
          );
          if (objIndex != -1) {
            cart.splice(objIndex, 1);
            this.cart.next(cart);
          }
          return resp;
        }
      });
    }
  }

  emptyCart(id_cliente: number) {
    if (this.authService.usuario.invitado) {
      this.emptyCartInv();
      this.cart.next([]);
    } else {
      const url = `${this.baseUrl}/carrito/vaciar`;
      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: {
          id_cliente: id_cliente,
        },
      };
      this.http.delete<boolean>(url, options).subscribe((resp) => {
        if (resp) {
          this.cart.next([]);
        }
      });
    }
  }
  // SC CATWEB-335 Funcion obtiene el carrito almacenado en mamoria para el usuario invitado.
  getCartInv() {
    let result = [];
    try {
      const products = JSON.parse(localStorage.getItem(this.cartLocalStorage));
      if (products != undefined) {
        result = products;
      } else {
        result = [];
      }
      return result;
    } catch (error) {
      return [];
    }
  }
  // SC CATWEB-335 Funcion actualiza el csrrito invitado en memoria.
  updateCartInnv(products: CarritoModel[]) {
    localStorage.setItem(this.cartLocalStorage, JSON.stringify(products));
  }
  // SC CATWEB-335 funcion que elimina el carrito invitado de memoria.
  emptyCartInv() {
    localStorage.removeItem(this.cartLocalStorage);
  }

  // SC CATWEB-335 actualiza el carrito del usuario invitado al carrito del cliente logueado.
  cartInvtoCartClient(cartInv) {
    cartInv.forEach((item) => {
      item.id_cliente = this.authService.usuario.id_cliente;
      this.changeCart(item, true);
    });
    this.emptyCartInv();
  }

  validarStock(cantidadCart: number, stock: number) {
    let controlStock = this.configService.config.productos.control_stock;
    let result = true;
    if (controlStock) {
      result = stock > 0 && cantidadCart <= stock ? true : false;
    }
    return result;
  }


  // validStockCart(carts:Array<CarritoModel>){
  validStockCart() {
    //let validcart = this.validCart.getValue();
    let validcart = false;
    let cart = this.cart.getValue();
 
    let item = cart.findIndex(
      (element) => element.stock <= 0 || element.cantidad > element.stock
    );

    if (item != -1) {
      validcart = false;
    } else {
      validcart = true;
    }
    return validcart;
    //this.validCart.next(validcart);
  }
  validPriceCart(){
    let validcart = false;
    let cart = this.cart.getValue();
    console.log(cart);
    let item = cart.findIndex(
      (element) => element.precio_actualizado
    );
    console.log(item);
    if (item != -1) {
      validcart = false;
    } else {
      validcart = true;
    }
    return validcart;
  }

  // SC CATWEB-403 funcion actualiza stock y precio de los productos cargados en carrito.
  updatePriceStock(items: Array<CarritoModel>) {
    
    let datos: ParamsProductModel = {
      Pagina: '0',
      Cant_Registros: 1,
      Nombre: '',
      Id_Marca: -1,
      Codigo: '',
      Codigo_Fabricante: '',
      Con_Stock: 'S',
      Id_Familia: -1,
      Incluye_Subfamilia: 'S',
      Codigo_Nombre_Descripcion: '',
      Id_Cliente: this.authService.usuario.id_cliente,
      Orden: 0,
      id_marca_aplic: -1,
      solo_con_aplic: 'N',
      nro_pieza: '',
      aplicacion: '',
      Id_Sucursal:-1  // SC CATWEB-410 se agrega parametro para consultar por sucursal.
    };

    // SC CATWEB-410 si esta habilitado el modo por sucursal en sinergial se debe obtener la sucursal para realizar la consulta de los productos
    if(this.authService.usuario.modo_por_sucursal){
      // this.sucursalService.currentDataSucSelect$.subscribe((resp) =>{
      //  datos.id_sucursal = resp.id_sucursal;
      // });
      datos.Id_Sucursal = parseInt(this.authService.usuario.ids_sucursales.split(',',1)[0]);
      // console.log(datos);
    }

    items.forEach((element) => {
      // SC CATWEB-403 se setea el codigo para realizar la consulta a la api.
      datos.Codigo = element.codigo;
      this.productoService.getProductos(datos).subscribe((resp) => {
        if (resp.length > 0 && resp[0]['id_articulo']) {
          // element.precio != parseFloat(resp[0]['precio_final'].toFixed(2))
          if (element.precio != resp[0]['precio_final']) {
            // SC CATWEB-403 si el precio del producto obtenido es distinto al del carrito se actualiza 
            element.precio = resp[0]['precio_final'];
            this.changeCart(element, false)
            element.precio_actualizado = true;
            // if (this.changeCart(element, false)) {
            //   console.log("entro");
            //   // SC CATWEB-403 se agrega la propiedad al producto si se actualizo el producto en carrito.
            //   element.precio_actualizado = true;
            // }
          }else{
            element.precio_actualizado = false;
          }
          element.stock = resp[0]['stock'];
        }
      });
      
    });
    console.log(items);
  }
}

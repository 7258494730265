import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../models/config.model';
import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { environment } from '../../../environments/environment';
import { UserModel } from '../models/auth/user.model';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

var httpHeaders: HttpHeaders;
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config: Config = {};
  public cargada = false;
  private url :string;
  private authLocalStorageData = 'data';
  constructor(private http:HttpClient) {
    
   }
   // SC CATWEB-383 Se modifica para cargar ademas de la conofiguracion directa del assets, la configuracion mediante recurso carrito.
   cargarConfig(){

    let versionFile = `${environment.versionFile}`
    let url = './assets/configs/page.json?V='+ versionFile;
    return this.http.get(url)
      .toPromise()
      .then(config => {
        this.cargada = true;
        this.config = config;

        this.url = this.config.servidor?.api_cart;

        this.getConfig().toPromise()
        .then(resp => {
          //console.log(resp);
          this.config = resp;
        })
        // .subscribe((resp) => {
        //   if(resp.servidor.api_server){
        //     this.config = resp;
        //   }
         
        // });
      });
     
  }

  // SC CATWEB-383 Se modifico para obtener la configuracion mediante la api carrito sin token.
  getConfig(){
    // console.log(this.url);
    // const data = localStorage.getItem(this.authLocalStorageData)
    // const authData : UserModel = JSON.parse(data!);
    // const token = authData.token;
    //   httpHeaders = new HttpHeaders({
    //     Authorization: `Bearer ${token}`,
    //   });
    const url = `${this.url}/config`;

    return this.http.get<Config>(url);
  }
  updateConfig(config: Config){
    const data = localStorage.getItem(this.authLocalStorageData)
    const authData : UserModel = JSON.parse(data!);
    const token = authData.token;
      httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    const url = `${this.url}/config_update`;
    return this.http.put<Config>(url, config, {headers: httpHeaders, observe: 'response'}).pipe(
      map(resp => resp),
      catchError( err => of(err) )
    );
  }
}

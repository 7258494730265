<form class="form-inline search-full" [class.open]="navServices.search">
  <div class="form-group w-100">
    <input
      class="form-control-plaintext"
      [(ngModel)]="text"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="searchTerm(text)"
      type="text"
      autocomplete="off"
      placeholder="{{ 'Search' | translate }}"
    />
    <app-feather-icons
      [icon]="'x'"
      class="close-search"
      (click)="searchToggle()"
    ></app-feather-icons>
    <div
      class="Typeahead-menu"
      [class.is-open]="searchResult"
      *ngIf="products?.length"
      id="search-outer"
    >
      <div
        class="ProfileCard u-cf"
        *ngFor="let menuItem of products | slice: 0:8"
      >
        <!-- <div
        class="ProfileCard u-cf"
        *ngFor="let menuItem of products | slice: 0:10"
      > -->
        <div class="ProfileCard-avatar">
          <img src="{{ menuItem?.imagen1 }}" style="width: 25px" />
        </div>
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            <!-- <a href="javascript:void(0)" class="realname" (click)="removeFix()">{{ -->
            <a
              href="javascript:void(0)"
              class="realname"
              (click)="QuickView.openModal(menuItem)"
              >{{ menuItem?.nombre }}
            </a>
          </div>
        </div>
      </div>
      <!-- SC CATWEB-396 Solo si la cantidad de articulos de la busqueda es mayor a 8 se agrega el link de ver n resultados para testo. -->
      <!-- SC CATWEB-396 se agrega el parametro search al path de productos, con el texto ingresado. -->
      <ng-container *ngIf="totalItems > 8">
        <a
          class="text-center"
          routerLink="/productos"
          [queryParams]="{ search: text }"
          (click)="searchToggle()"
        >
          Ver {{ totalItems }} resultados para {{ text }}</a
        >
      </ng-container>
    </div>
    <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">Resultado de la búsqueda.</div>
      </div>
    </div>
  </div>
</form>

<app-product-view [mostrarPrecio]="verPrecios" #quickView></app-product-view>

<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-xl-5" [ngStyle]="{'background-image': 'url(assets/images/login/3.jpg)',
                                      'background-size': 'cover',
                                      'background-position': 'center center',
                                      'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/3.jpg" alt="looginpage">
    </div>
    <div class="col-xl-7 p-0">    
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-left" href="#">
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com">
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>
                <button class="btn btn-primary btn-block" type="submit">Sign in</button>
              </div>
              <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">
                    <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons> LinkedIn 
                  </a>
                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                   <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook
                  </a>
                </div>
              </div>
              <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <div class="d-none d-xl-flex col-xl-5 login-two">
      <div class="login-tittle">
        <h2>{{tituloLogin}}</h2>
        <p>{{textoLogin}}</p>
      </div>
    </div>
    <div class="col-xl-7 p-0">    
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              <h1>{{tituloPage}}</h1>
            </a>
            
          </div>
          <!-- SC CATWEB-332 Formulario para login de usuario -->
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Iniciar Sesión</h4>
              <p></p>
              <div class="form-group">
                <label class="col-form-label">Usuario</label>
                <input class="form-control" type="text" required="" placeholder="Usuario" formControlName="usuario" list="usuariosRecordados">
                <!-- MV CATWEB-322 Datalist con usuarios guardados -->
                <datalist id="usuariosRecordados">
                  <option *ngFor="let usuario of usuariosGuardados" [value]="usuario">
                </datalist>
                  <div *ngIf="loginForm.controls.usuario.touched && loginForm.controls.usuario.errors?.required"
                      class="text text-danger mt-1">
                      Usuario es requerido
                  </div>
                  <div *ngIf="loginForm.controls.usuario.touched && loginForm.controls.usuario.errors?.usuario"
                      class="text text-danger mt-1">
                      Usuario no valido
                  </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><i class="fa fa-eye-slash"></i></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><i class="fa fa-eye"></i></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text text-danger mt-1">
                      Contraseña es requerida
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" formControlName="recordarme">
                  <label class="text-muted" for="checkbox1">Recordarme</label>
                </div>
                <!-- SC CATWEB-332 Link a recuperar contraseña -->
                <a [routerLink]="'/authentication/forgot-password'" class="link">Olvidé mi contraseña</a>

                <button class="btn btn-primary btn-block"
                  [class.loader--text]="authService.showLoader"
                  [disabled]="!loginForm.valid || authService.showLoader"
                  (click)="loginUser()"
                  type="submit"><span>{{ authService.showLoader ? '' : 'Ingresar' }}</span>
                </button>

              </div>
              <ng-container *ngIf="invitado">
                <div class="social mt-2">
                  <div class="btn btn-block btn-showcase">
                    <a class="btn btn-light" (click)="loginInvitado()">
                        <app-feather-icons [icon]="'user'" class="txt-fb"></app-feather-icons>Ingresar como Invitado
                    </a>
                  </div>
                </div>
              </ng-container>
              

              <p class="mt-4 mb-0">
                <!-- SC CATWEB-332 Link Crear cuenta de usuario para clientes registrados en sinergial -->
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Registro para Clientes</a>
              </p>
              
            </form>
          </div>
          <!-- SC CATWEB-332 Formulario End. -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-customizer></app-customizer>
import { Routes } from '@angular/router';

export const config: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../components/admin/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'config',
    loadChildren: () => import('../../components/admin/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
  },
];

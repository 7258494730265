<div class="container-fluid">
    <div class="row">
      <!-- <div class="col-xl-5" [ngStyle]="{'background-image': 'url(assets/images/login/old_icon.png)',
                                        'background-size': 'cover',
                                        'background-position': 'center center',
                                        'display': 'block'}">
        <img class="bg-img-cover bg-center d-none" src="assets/images/login/old_icon.png" alt="looginpage">
      </div> -->
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
                <a class="logo" routerLink='/'>
                  <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                  <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                  <h1>{{config.cliente!.titulo_page}}</h1>
                </a>
                
              </div>
            <div class="login-main"> 
              <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate="" [formGroup]="loginForm">
                <h4>Iniciar Sesión</h4>
                <p>Ingrese su usuario y contraseña para iniciar sesión</p>
                <div class="form-group">
                  <label class="col-form-label">Email</label>
                  <input class="form-control" type="email" required="" placeholder="Ingrese su email" formControlName="email">
                  <div class="invalid-tooltip" *ngIf="validaciones.controlHasError(loginForm, 'email', 'required')">Email requerido.</div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********" formControlName="password">
                  <div class="invalid-tooltip" *ngIf="validaciones.controlHasError(loginForm, 'password', 'required')">Contraseña es requerida.</div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><i class="fa fa-eye-slash"></i></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><i class="fa fa-eye"></i></div>
                </div>
                <br>
                <div class="form-group mb-0">
                  <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                  <button class="btn btn-primary btn-block" type="button" [disabled]="!loginForm.valid" (click)="loginAdmin()">Iniciar Sesión</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 

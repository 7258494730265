import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// SC CATWEB-382 mensajes de Errores.
const MSG_400 = '400: Solicitud Incorrecta';
const MSG_401 = '401: Unauthorized';
const MSG_402 = '402: Payment Required';
const MSG_403 = '403: Acceso no Permitido'; // Forbidden
const MSG_404 = '404: Recurso no Encontrado'; // Not Found
const MSG_405 = '405: Method Not Allowed';
const MSG_406 = '406: Not Acceptable';
const MSG_407 = '407: Proxy Authentication Required';
const MSG_408 = '408: Request Timeout';
const MSG_409 = '409: Conflict';
const MSG_410 = '410: Gone';
const MSG_411 = '411: Length Required';
const MSG_412 = '412: Precondition Failed';
const MSG_413 = '413: Request Entity Too Large';
const MSG_414 = '414: Request-URI Too Long';
const MSG_415 = '415: Unsupported Media Type';
const MSG_416 = '416: Requested Range Not Satisfiable';
const MSG_417 = '417: Expectation Failed';
const MSG_500 = '500: Error Interno Servidor'; // Internal Server Error
const MSG_501 = '501: Not Implemented';
const MSG_502 = '502: Bad Gateway';
const MSG_503 = '503: Service Unavailable';
const MSG_504 = '504: Gateway Timeout';
const MSG_505 = '505: HTTP Version Not Supported';

const MSG_DEFAULT = 'Disculpe, temporalmente fuera de servicio, intente más tarde por favor';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toastr: ToastrService) {}

  // SC CATWEB-382 emite mensaje dependiendo el status de la solicitud.
  getError(status: number) {
    switch (status) {
      case 500:
        this.toastr.error(MSG_500);
        break;
      case 400:
        this.toastr.error(MSG_400);
        break;
      case 404:
        this.toastr.error(MSG_404);
        break;
      case 403:
        this.toastr.error(MSG_403);
        break;
      case 401:
        this.toastr.error(MSG_401);
        break;
      case 402:
        this.toastr.error(MSG_402);
        break;
      case 405:
        this.toastr.error(MSG_405);
        break;
      case 406:
        this.toastr.error(MSG_406);
        break;
      case 407:
        this.toastr.error(MSG_407);
        break;
      case 408:
        this.toastr.error(MSG_408);
        break;
      case 409:
        this.toastr.error(MSG_409);
        break;
      case 410:
        this.toastr.error(MSG_410);
        break;
      case 411:
        this.toastr.error(MSG_411);
        break;
      case 412:
        this.toastr.error(MSG_412);
        break;
      case 413:
        this.toastr.error(MSG_413);
        break;
      case 414:
        this.toastr.error(MSG_414);
        break;
      case 501:
        this.toastr.error(MSG_501);
        break;
      case 502:
        this.toastr.error(MSG_502);
        break;
      case 503:
        this.toastr.error(MSG_503);
        break;
      case 504:
        this.toastr.error(MSG_504);
        break;
      case 505:
        this.toastr.error(MSG_505);
        break;
      default:
        this.toastr.error(MSG_DEFAULT);
        break;
    }
  }
}

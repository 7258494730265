import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigService } from '../../../shared/services/config.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProvinciaService {

  private baseUrl = this.configService.config.servidor?.api_server;
  private accessToken: string = "ddh0mL2jg7qfLcCASWYtZzOjYQv59Gla";

  constructor(private http: HttpClient,
              public configService: ConfigService) { }

  getProvincias(): Observable<any[]> {
    const url = `${this.baseUrl}/Provincia`;
    const params = new HttpParams().set('Access_Token',this.accessToken);

    return this.http.get<any[]>(url, {params: params});
  }

  getLocalidades(id_provincia: number): Observable<any[]> {
    const url = `${this.baseUrl}/Localidad`;
    const params = new HttpParams().set('id_provincia', id_provincia).set('Access_Token',this.accessToken);

    return this.http.get<any[]>(url, {params: params});
  }
}

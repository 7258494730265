import { Component, OnInit } from '@angular/core';
import { Config } from '../../../../shared/models/config.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ValidacionesService } from 'src/app/shared/services/validaciones/validaciones.service';
import { Router } from '@angular/router';
import { AdminModel } from 'src/app/shared/models/auth/admin.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/shared/models/auth/user.model';
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  public loginForm: FormGroup;
  public show: boolean = false;
  public config: Config;
  private user: AdminModel;
  private accessToken: string = environment.AccessToken;

  constructor(private fb: FormBuilder,
              private configService: ConfigService,
              public validaciones: ValidacionesService,
              public router: Router,
              public authService: AuthService,
              public toastr: ToastrService) { 
    
  }

  ngOnInit() {
    this.config = this.configService.config;
    
    this.loginForm = this.fb.group({
      email: ['',  Validators.compose([Validators.required, , Validators.email])],
      password: ['', Validators.compose([Validators.required])]
    });
  }
  

  showPassword() {
    this.show = !this.show;
  }

  loginAdmin(){
    
    const susc = this.authService.loginAdmin(this.loginForm.controls.email.value, this.loginForm.controls.password.value).subscribe((resp) => {
      // console.log(resp);
      if(resp.status){
        let user = {
          nombre : resp.name,
          token : resp.token,
          admin : resp.admin,
        };
       
        this.authService.setAuthFromLocalStorage(user);
        this.router.navigateByUrl('/configuracion/home');
      }else{
        this.toastr.error(resp.message);
      }
    });
    susc.unsubscribe;
  }

}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-client',
  templateUrl: './modal-client.component.html',
  styleUrls: ['./modal-client.component.scss']
})
export class ModalClientComponent implements OnInit {

  @ViewChild("modalClient", { static: false }) ModalClient: TemplateRef<any>;
  @Input() clients: any[];
  public closeResult: string;
  public modalOpen: boolean = false;
  public selectgroupby: string = "";
  public clientForm: FormGroup = this.fb.group({
    selectedClient: ["0"]
  });
  

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  setClient(){

    let index = this.clientForm.value.selectedClient;

    this.activeModal.close(this.clients[index]);
  }

}

import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidacionesService {

  // MV CATWEB-321 Expresion regular para validar email.
  private _emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  get emailPattern() {
    return this._emailPattern;
  }

  constructor() { }

  // MV CATWEB-321 Funcion que retorna si un campo es valido
  isControlValid(form: FormGroup, controlName: string): boolean {
    const control = form.get(controlName);
    
    return control.valid && (control.dirty || control.touched);
  }

  // MV CATWEB-321 Funcion que retorna si un campo es invalido
  isControlInvalid(form: FormGroup, controlName: string): boolean {
    const control = form.get(controlName);

    return control.invalid && (control.dirty || control.touched);
  }
   // MV Funcion que retorna si un campo tiene un error especifico.
  controlHasError(form: FormGroup, controlName: string, validation: string): boolean {
    const control = form.get(controlName);

    return control.hasError(validation) && (control.dirty || control.touched);
  }

  // MV CATWEB-321 Funcion que retorna si un campo ha sido instanciado
  isControlTouched(form: FormGroup, controlName: string): boolean {
    const control = form.get(controlName);

    return control.dirty || control.touched;
  }

  // MV CATWEB-321 Funcion para validar que las contraseñas coincidan.
  matchPassword(campoClave: string, campoRetypeClave: string) {

    return (control: AbstractControl) => {
      
      const password = control.get(campoClave);
      const confirmPassword = control.get(campoRetypeClave);

      if (password.value !== confirmPassword.value) {
        return confirmPassword.setErrors({ ConfirmPassword: true });
      } else {
        return null;
      }
    }
  }

  mayorDeEdad(campoFecha: string) {

    return (control: AbstractControl) => {

      const fechaCampo = control.get(campoFecha);
      const fechaIngresada = fechaCampo.value.split("-");
      const fechaIngresadaNum = [parseInt(fechaIngresada[0]), parseInt(fechaIngresada[1]), parseInt(fechaIngresada[2])];
      const fecha = new Date();
      const fechaHoy = [fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate()];

      let edad = 0;
      if(fechaHoy[1] >= fechaIngresadaNum[1] && fechaHoy[2] >= fechaIngresadaNum[2]) {
        edad = fechaHoy[0] - fechaIngresadaNum[0];
      } else {
        edad = fechaHoy[0] - fechaIngresadaNum[0] - 1;
      }

      if (edad < 18) {
        return fechaCampo.setErrors({ MenorDeEdad: true });
      } else {
        return null;
      }
    }
  }
}

<ng-container *ngIf="!invitado;else ingresar">
  <div class="media profile-media" (click)="toggleNotificationMobile()">
    <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="" />
    <div class="media-body">
      <span>{{ usuario.usuario }}</span>
      <p class="mb-0 font-roboto">
        <ng-container *ngIf="admin">Admin </ng-container>
        <ng-container *ngIf="usuario.modo_por_sucursal && sucursal">
          {{sucursal.nombre}} - {{sucursal.localidad}}
        </ng-container>
        
        <i class="middle fa fa-angle-down"></i>
      </p>
    </div>
  </div>
  <ul class="profile-dropdown onhover-show-div" [class.active]="openNotification">
    <ng-container *ngIf="!admin">
    
    <li>
      <a [routerLink]="['/cuenta-usuario/cuenta']"
        ><app-feather-icons [icon]="'user'"></app-feather-icons
        ><span>{{ "Account" | translate }} </span></a
      >
    </li>
  </ng-container>
    <li>
      <a [href]="exitPage" (click)="logout()"
        ><app-feather-icons [icon]="'log-out'"></app-feather-icons
        ><span>{{ "Log out" | translate }}</span></a
      >
    </li>
  </ul>
</ng-container>
<ng-template #ingresar>
  <a [routerLink]="['/authentication/logout']"
  ><app-feather-icons [icon]="'user'"></app-feather-icons
  ><span> {{ "Log in" | translate }}</span></a
>
</ng-template>


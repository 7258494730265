import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-login-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class LoginSimpleComponent implements OnInit {

  @Input() registro:boolean = false;
  @Input() invitado:boolean = false;
  @Input() tituloLogin: string = "";
  @Input() textoLogin: string = "";
  @Input() usuariosGuardados: string[] = [];
  @Input() tituloPage:string = "";

  @Output() login = new EventEmitter;
  @Output() loginInv = new EventEmitter;

  public show: boolean = false;
  public loginForm: FormGroup;

  constructor(public authService: AuthService,
    private fb:FormBuilder) { 
    this.loginForm = this.fb.group({
      usuario: ['', [Validators.required, Validators.maxLength(25)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(16),
        ],
      ],
      recordarme: [true],
    });
  }

  ngOnInit() {
    
  }

  showPassword() {
    this.show = !this.show;
  }

  loginUser(){
    this.login.emit(this.loginForm.value);
  }
  loginInvitado(){
    this.loginInv.emit();
  }

}

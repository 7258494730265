import { HttpClient } from '@angular/common/http';
import { Theme } from '../models/theme.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public config = {
    settings: {
       layout: '',
       layout_type: '',
       layout_version: '',
       sidebar_type: '',
    },
    color: {
       primary_color: '',
       secondary_color: ''
    }
 }

  constructor(
    private http:HttpClient) {
    this.cargarTheme();
    
  }

  public obtenerLayout(){
    return this.http.get<Theme>('assets/configs/theme.json');
  }

  private cargarTheme(){
    this.http.get<Theme>('assets/configs/theme.json')
      .subscribe( (resp:Theme) => {
      
        this.config = resp;
        if(this.config.settings.layout_type == 'rtl')
        document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

        document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
        document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
      });
  }

}

<ng-template #quickView let-modal>
  <div class="modal-content">

    <!-- Botones para volver atras en la navegacion de productos y para cerrar el modal -->
    <div class="row m-l-0 m-r-0 m-t-10" style="display: flex; justify-content: space-between;" *ngIf="backProducts.length > 0">
      <ng-container class="col-6">
        <button class="btn" style="color:gray;"
        (click)="back()"> <span aria-hidden="true"><i class="fa fa-arrow-left"></i></span></button>
      </ng-container>
      <ng-container class="col-6">
        <button class="close" (click)="modal.dismiss('Cross click')" style="padding: 6px 28px;">
        <span aria-hidden="true">×</span>
        </button>
      </ng-container>
    </div>
    <div class="row m-l-0 m-r-0 m-t-10" style="display: flex; justify-content: end;" *ngIf="!(backProducts.length > 0)">
      <ng-container>
        <button class="close" (click)="modal.dismiss('Cross click')" style="padding: 6px 28px;">
        <span aria-hidden="true">×</span>
        </button>
      </ng-container>
    </div>
    <div class="modal-header product-modal">
      <div class="product-box row">
        <ng-container *ngIf="producto.en_oferta === 'S'">
          <div class="ribbon ribbon-info" style="margin-top: 30px;">
            {{ "Sale" | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="producto.en_novedad === 'S'">
          <div class="ribbon ribbon-success" style="margin-top: 70px;">
            {{ "News" | translate }}
          </div>
        </ng-container>

        <div class="product-img col-md-6" style="width: 369px; height: 417px; padding: 30px;">

          <!-- SC CATWEB-337 10/09/2021 carousel para las imagenes del producto -->
          <div style="width: 100%; height: 100%;padding-top: 20%;">
          <owl-carousel-o [options]="owlcarousel14Options" #owlCar class="product-slider" >
            <ng-template carouselSlide class="item" [id]="img.id" *ngFor="let img of images">
                <img class="img-fluid col-md-12" [src]="img.nombre" alt="" (click)="displayBasic2 = true" style="cursor: pointer;object-fit: contain; max-height: 300px;"/>
            </ng-template>
          </owl-carousel-o>  
          </div>  

          <!-- MV CATWEB-374 Galeria de imagenes del producto en pantalla completa  -->
          <p-galleria [(value)]="images" [(visible)]="displayBasic2" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
            [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
            <ng-template pTemplate="item" let-item>
              <img [src]="item.nombre" style="width: 100%; display: block;"/>
            </ng-template>
          </p-galleria>

        </div>
        <div class="product-details col-md-6 text-left">
          <h4 style="white-space: pre-line">{{ producto.nombre }}</h4>
          <ng-container *ngIf="mostrarPrecio">
            <!-- Precio Final -->
            <div *ngIf="config.productos.muestra_precio_final || invitado">
              <div class="product-price">${{producto.precio_final | currency:'' :'':'.2-2':'es'}}
              <p>{{config.productos.titulo_precio_final}}</p></div>
            </div>

            <!-- Precio Neto -->
            <div *ngIf="config.productos.muestra_precio_neto && !invitado">
              <div class="product-price">${{producto.precio_neto | currency:'' :'':'.2-2':'es'}}
              <p>{{config.productos.titulo_precio_neto}}</p></div>
            </div>

            <!-- Precio Lista -->
            <div *ngIf="config.productos.muestra_precio_lista && !invitado">
              <div class="product-price">${{producto.precio_lista | currency:'' :'':'.2-2':'es'}}
              <p>{{config.productos.titulo_precio_lista}}</p></div>
            </div>
          </ng-container>
          <hr />
          <div>
            <table class="product-page-width">
              <tbody>
                <tr>
                  <td><b>Marca &nbsp;&nbsp;&nbsp;:</b></td>
                  <td>{{ producto.marca }}</td>
                </tr>
                <tr>
                  <td><b>Stock &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td class="txt-success">
                    <ng-container *ngIf="producto.stock <= 0"
                      ><label class="badge badge-danger"
                        >{{tituloSinStock}}</label
                      ></ng-container
                    >
                    <ng-container
                      *ngIf="
                        producto.stock > 0 &&
                        producto.stock <= producto.cantidad_minima
                      "
                      ><label class="badge badge-warning"
                        >Mínimo</label
                      ></ng-container
                    >
                    <ng-container
                      *ngIf="
                        producto.stock > 0 &&
                        producto.stock > producto.cantidad_minima
                      "
                      ><label class="badge badge-success"
                        >Disponible</label
                      ></ng-container
                    >
                  </td>
                </tr>
                <tr>
                  <td><b>Rubro &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td>{{ producto.rubro }}</td>
                </tr>
                <tr>
                  <td><b>Código &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td>{{ producto.codigo }}</td>
                </tr>
                <tr *ngIf="config.productos.muestra_unidad_bulto">
                  <td><b>Unidad &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td>{{ producto.cantidad_unidad }}</td>
                </tr>
                <tr *ngIf="config.productos.muestra_unidad_bulto">
                  <td><b>Bulto &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td>{{ producto.cantidadpaq }}</td>
                </tr>

                <!-- <ng-container *ngIf="muestraNeto">            
                  <td><b>Precio Neto&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                  <td><div class="product-price">${{ producto.precio_neto | currency: "":"":".2-2":"es"}}</div></td>            
                </ng-container>
                  <ng-container *ngIf="muestraLista">
                    <td><b>Precio Lista &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                    <td><div class="product-price">${{ producto.precio_lista | currency: "":"":".2-2":"es"}}</div></td>
                  </ng-container> -->
              </tbody>
            </table>
          </div>
          <hr />

          <div class="product-qnty">
            <h6 class="f-w-600">{{ "Quantity" | translate }}</h6>

            <fieldset>
              <div class="input-group bootstrap-touchspin" style="width: 50%;">
                <button
                  class="btn btn-primary btn-square bootstrap-touchspin-down"
                  type="button"
                  (click)="decrement()"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <input
                  class="touchspin form-control text-center"
                  type="text"
                  value="{{ counter }}"
                  [(ngModel)]="counter"
                  (keyup.enter)="add(producto)"
                />
                <!-- <div class="input-group-append ml-0"> -->
                  <button
                    class="btn btn-primary btn-square bootstrap-touchspin-up"
                    type="button"
                    (click)="increment()"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                <!-- </div> -->
              </div>
            </fieldset>
            <ng-container *ngIf="producto.stock > 0 && controlStock">
              <p>({{producto.stock}} Disponibles)</p>
            </ng-container>
            
            <div class="addcart-btn">
              <button
                class="btn btn-primary mr-1"
                type="button"
                (click)="add(producto)"
                *ngIf="mostrarPrecio"
                [disabled]="counter < 1 || (producto.stock < 1 && controlStock)"
              >
                {{ "Add to Cart" | translate }}
              </button>
              <!-- <button class="btn btn-primary" type="button">View Details</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="container">

        <ngb-tabset>
          <ngb-tab title="{{ 'Product Details' | translate }}">
            <ng-template ngbTabContent>
              <div class="text-justify" style="white-space: pre-line;" [innerHTML] = producto.descripcion>
                
              </div>
            </ng-template>
          </ngb-tab>
          <ng-container *ngIf="hasAplicacion">
            <ngb-tab title="Aplicaciones">
              <ng-template ngbTabContent>
                <div
                  class="table-responsive product-list-custom custom-datatable"
                >
                  <!-- SC CATWEB-351 09/09/2021 Se agrega el campo Descripcion a la tabla de aplicaciones -->
                  <div class="table-responsive">
                    <ngx-datatable
                      class="bootstrap"
                      [rows]="aplicaciones"
                      [columns]="[
                        { name: 'Marca', prop: 'marca' },
                        { name: 'Modelo', prop: 'modelo' },
                        { name: 'Descripción', prop: 'descripcion' }
                      ]"
                      [columnMode]="'force'"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                      [limit]="5"
                      [sorts]="[{ prop: 'marca', dir: 'asc' }]"
                    >
                    </ngx-datatable>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ng-container>
          <ng-container *ngIf="hasEquivalente">
            <ngb-tab title="Equivalencias">
              <ng-template ngbTabContent>
                <div
                  class="table-responsive product-list-custom custom-datatable"
                >
                  <div class="table-responsive">
                    <ngx-datatable
                      class="bootstrap"
                      [rows]="equivalentes"
                      [columnMode]="'force'"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                      [limit]="5"
                      [sorts]="[{ prop: 'articulo_o', dir: 'asc' }]"
                    >
                      <ngx-datatable-column
                        name="Código"
                        [flexGrow]="2"
                        prop="codigo_ao"
                      >
                        <ng-template
                          let-value="value"
                          ngx-datatable-cell-template
                          let-row="row"
                        >
                          {{ value }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        name="Producto"
                        [flexGrow]="2"
                        prop="articulo_o"
                      >
                        <ng-template
                          let-value="value"
                          ngx-datatable-cell-template
                          let-row="row"
                        >
                          <a class="text-inherit" href="javascript:void(0)"
                          (click)="openModal2(row.codigo_ao)">
                            {{ value }}
                          </a>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ng-container>
          <ng-container *ngIf="hasRelacionada">
            <ngb-tab title="Partes Relacionadas">
              <ng-template ngbTabContent>
                <div
                  class="table-responsive product-list-custom custom-datatable"
                >
                  <div class="table-responsive">
                    <ngx-datatable
                      class="bootstrap"
                      [rows]="relacionadas"
                      [columnMode]="'force'"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                      [limit]="5"
                      [sorts]="[{ prop: 'articulo_o', dir: 'asc' }]"
                    >
                      <ngx-datatable-column
                        name="Código"
                        [flexGrow]="2"
                        prop="codigo_ao"
                      >
                        <ng-template
                          let-value="value"
                          ngx-datatable-cell-template
                          let-row="row"
                        >
                          {{ value }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        name="Producto"
                        [flexGrow]="2"
                        prop="articulo_o"
                      >
                        <ng-template
                          let-value="value"
                          ngx-datatable-cell-template
                          let-row="row"
                        >
                          <a class="text-inherit" href="javascript:void(0)"
                          (click)="openModal2(row.codigo_ao)">
                            {{ value }}</a>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ng-container>
        </ngb-tabset>
      </div>
    </div>
   
  </div>
</ng-template>

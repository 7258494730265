<div class="cart-box" (click)="toggleCart()">
  <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
  <span class="badge badge-pill badge-primary">{{totalQuantity}}</span>
</div>
<ul class="cart-dropdown onhover-show-div" [class.active]="openCart">
  <div class="pre-scrollable">
  <li>
    <h6 class="mb-0 f-20">{{"Shoping Bag"| translate}}</h6>
    <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
  </li>

  <ng-container *ngFor="let c of cart">
  <li class="mt-0">
    <div class="media">
      <img class="img-fluid rounded-circle mr-3 img-60" src="{{c.imagen}}" alt="">
     
      <div class="media-body col-10">
        <span>{{c.nombre}}</span>
        <ng-container *ngIf="c.precio_actualizado">
          <p class="text-info">{{modificado}}</p>
        </ng-container>
        <!-- <p>Yellow(#fcb102)</p> -->
        
        <div class="qty-box">
          <div class="input-group">
            <span class="input-group-prepend">
              <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="" (click)="decrement(c.id_producto)" [disabled]="controlStock && c.stock <= 0"><i data-feather="minus"></i></button>
            </span>
            
            <input class="form-control input-number" type="text" name="quantity" value={{c.cantidad}} id="{{'qty' + c.id_producto}}" (keyup.enter)="update(c)" [disabled]="controlStock && c.stock <= 0" >
        
            <span class="input-group-prepend">
              <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="" (click)="increment(c.id_producto )" [disabled]="controlStock && c.stock <= 0"><i data-feather="plus"></i></button>
            </span>
          </div>
          <ng-container *ngIf="controlStock">
            <ng-container *ngIf="c.stock > 0">
              <p>{{c.stock}} disponibles</p>
              <ng-container *ngIf="c.stock < c.cantidad">
                <p class="text-danger">{{noDisponible}}</p>
              </ng-container>
            </ng-container>
           
            <ng-container *ngIf="c.stock <= 0">
              <p class="text-danger">{{sinStock}}</p>
            </ng-container>
          </ng-container>
          
        </div>
        <h6 class="text-right text-muted">${{(c.cantidad * c.precio) | currency:'' :'':'.2-2':'es'}}</h6>      
      </div>
      <div class="close-circle col-2">
        <a ngbTooltip="Eliminar" (click)="delete(c)">
          <app-feather-icons [icon]="'trash-2'"></app-feather-icons>
        </a>
        <br />
        <ng-container *ngIf="controlStock && c.stock > 0 || !controlStock">
          <a ngbTooltip="Actualizar" (click)="update(c)" style="margin-right: 10px;">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </ng-container>
        
      </div>
    </div>
  </li>
</ng-container>
</div>
<br />
  <li>
    <div class="total">
      <h6 class="mb-2 mt-0 text-muted">{{'Order Total' | translate}} : <span class="f-right f-20">${{totalPrice | currency:'' :'':'.2-2':'es'}}</span></h6>
    </div>
  </li>
 
  <li>
    <a class="btn btn-block btn-primary view-cart" routerLink='/mi-carrito/cart'>
      {{'Go to shoping bag' | translate}}
    </a>
    <ng-container *ngIf="totalQuantity >0">
      <!-- <ng-container *ngIf="controlStock && verificaStock || !controlStock"> -->
        <a class="btn btn-block btn-secondary view-cart" href="javascript:void(0)" (click)="finalizar()">{{'Checkout' | translate}}</a>
      <!-- </ng-container> -->
  </ng-container>
  </li>
</ul>
<ng-container *ngIf="false">
  <video autoplay muted loop preload id="myVideo" >
    <source src="assets/data/login/video.mp4" type="video/mp4">
  </video>
</ng-container>
<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              <h1>{{tituloPage}}</h1>
            </a>
            
          </div>
          <!-- SC CATWEB-332 Formulario para login de usuario -->
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <h4>{{tituloLogin}}</h4>
              <p>{{textoLogin}}</p>
              <div class="form-group">
                <label class="col-form-label">Usuario</label>
                <input class="form-control" type="text" required="" placeholder="Usuario" formControlName="usuario" list="usuariosRecordados">
                <!-- MV CATWEB-322 Datalist con usuarios guardados -->
                <datalist id="usuariosRecordados">
                  <option *ngFor="let usuario of usuariosGuardados" [value]="usuario">
                </datalist>
                  <div *ngIf="loginForm.controls.usuario.touched && loginForm.controls.usuario.errors?.required"
                      class="text text-danger mt-1">
                      Usuario es requerido
                  </div>
                  <div *ngIf="loginForm.controls.usuario.touched && loginForm.controls.usuario.errors?.usuario"
                      class="text text-danger mt-1">
                      Usuario no valido
                  </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><i class="fa fa-eye-slash"></i></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><i class="fa fa-eye"></i></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text text-danger mt-1">
                      Contraseña es requerida
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" formControlName="recordarme">
                  <label class="text-muted" for="checkbox1">Recordarme</label>
                </div>
                <!-- SC CATWEB-332 Link a recuperar contraseña -->
                <a [routerLink]="'/authentication/forgot-password'" class="link">Olvidé mi contraseña</a>

                <button class="btn btn-primary btn-block"
                  [class.loader--text]="authService.showLoader"
                  [disabled]="!loginForm.valid || authService.showLoader"
                  (click)="loginUser()"
                  type="submit"><span>{{ authService.showLoader ? '' : 'Ingresar' }}</span>
                </button>

              </div>
              <ng-container *ngIf="invitado">
                <div class="social mt-2">
                  <div class="btn btn-block btn-showcase">
                    <a class="btn btn-light" (click)="loginInvitado()">
                        <app-feather-icons [icon]="'user'" class="txt-fb"></app-feather-icons>Ingresar como Invitado
                    </a>
                  </div>
                </div>
              </ng-container>
              

              <p class="mt-4 mb-0">
                <!-- SC CATWEB-332 Link Crear cuenta de usuario para clientes registrados en sinergial -->
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Registro para Clientes</a>
              </p>
              <ng-container *ngIf= "registro">
                <p class="mt-1 mb-0 ml-2">
                  <!-- MV CATWEB-321 Link Registro nuevo usuario web no registrado en sinergial -->
                  ¿Sos nuevo cliente?<a [routerLink]="'/authentication/register/nuevo-usuario'"> Crea tu cuenta</a>
                </p>
              </ng-container>
              
            </form>
          </div>
          <!-- SC CATWEB-332 Formulario End. -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-customizer></app-customizer>

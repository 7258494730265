import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Footer } from '../../models/footer/footer.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();
  public footer: Footer = {
    footer:false,
    icon_whatsapp:{
      active:false,
      link:""
    },
    items:[]
  };
  public version: string="";

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.getFooter();
    this.version = environment.version;
  }

  private getFooter(){
    const url = './assets/data/footer/footer.json?V='+ `${environment.versionFile}`;
    return this.http.get<Footer>(url).subscribe((resp) =>{
      // console.log(resp);
      this.footer = resp;
    });
 
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { environment } from '../../../../environments/environment';
import { ProductoModel } from '../../models/producto/producto.model';
import { EquivalenteModel } from '../../models/producto/equivalente.model';
import { AplicacionModel } from '../../models/producto/aplicacion.model';
import { ImagenModel } from '../../models/producto/imagen.model';
import { catchError, map, tap} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  private baseUrl = this.configService.config.servidor?.api_server;
  private accessToken: string = environment.AccessToken;

  constructor(private http:HttpClient, private configService: ConfigService) { }

  
getProductos(params:any){
  const url = `${this.baseUrl}/Articulo`;
  const httpParams = this.armarParams(params);
  //const httpParams = new HttpParams(params);
  return this.http.get<ProductoModel[]>(url, {params:httpParams}).pipe(
    map((resp: ProductoModel[]) => {
      //console.log(resp);
      resp.forEach(element => {

        element.imagen1 = this.mapImagenPath(element.imagen1);
        element.imagen2 = this.mapImagenPath(element.imagen2);

        let stock = parseFloat(element.stock.replace(/,/g, "."));
        let min = parseFloat(element.cantidad_minima.replace(/,/g, "."));
        stock = this.mapStock(stock, element.comprometido, element.reservado);
        element.stock = stock;
        element.cantidad_minima = min;
        
      });
      return resp;
    })
  );
}

getEquivalentes(tipo_relacion: string, id_articulo: number, codigo: string){
  const url = `${this.baseUrl}/ArticuloEquivalencia`;
  let params = {};
  {Object.assign(params, {Access_Token: this.accessToken});}
  {Object.assign(params, {id_articulo: id_articulo});}
  {Object.assign(params, {tipo_relacion: tipo_relacion});}
  {Object.assign(params, {Plataforma: 2});}

  return this.http.get<EquivalenteModel[]>(url, {params}).pipe(
    map((resp: EquivalenteModel[]) => {
      resp.forEach(element => {
        if(element.codigo_ao == codigo){
          element.codigo_ao = element.codigo_ad;
          element.articulo_o = element.articulo_d;
        }
      });
      return resp;
    })
  );

}

getAplicaciones(id_articulo: number){
  const url = `${this.baseUrl}/ArticuloAplicacion`;
  let params = {};
  {Object.assign(params, {Access_Token: this.accessToken});}
  {Object.assign(params, {id_articulo: id_articulo});}
  {Object.assign(params, {Plataforma: 2});}

  return this.http.get<AplicacionModel[]>(url, {params});

}

getImagenesArticulo(id_articulo: number){
  const url = `${this.baseUrl}/ArticuloMedia`;
  const params = {
    Access_Token: this.accessToken,
    id_articulo: id_articulo,
    Plataforma: 2,
    tipo_media: 'I'
  };

  return this.http.get<ImagenModel[]>(url, {params}).pipe(
    map(resp => {
      resp.forEach(element => {
        element.nombre = this.mapImagenPath(element.nombre);
      });
      return resp;
    })
    )
  }


armarParams(datos:any) : any {
  let params = {};
  {Object.assign(params, {Access_Token: this.accessToken});}
  {Object.assign(params, {Parametros: JSON.stringify(datos)});}
  {Object.assign(params, {Plataforma: 2});}
  return params;
}

mapImagenPath(imagen:string){
  const urlImagen = this.configService.config.servidor!.images_server;
  if(imagen != ""){
    imagen = `${urlImagen}/`+imagen;
  }else{
    imagen = "assets/images/productos/default.png";
  }
  return imagen;
}
mapStock(stock:number, comprometido:string, reservado:string){
  const stock_disponible = this.configService.config.productos?.stock_disponible;
  if(stock_disponible === 1 || stock_disponible === 2) {
    const stock_comprometido = parseFloat(comprometido.replace(/,/g, "."));
    stock = stock - stock_comprometido;
    if(stock_disponible === 2){
      const stock_reservado = parseFloat(reservado.replace(/,/g, "."));
      stock = stock - stock_reservado;
    }
  }
  return stock;
}

deleteProduct(){
  return this.http.delete(`${this.baseUrl}/api/Productoes/39`).pipe(
    catchError(err => {
      return of(err);
    }),
  );

}

}

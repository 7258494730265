import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductViewComponent } from 'src/app/components/productos/product-view/product-view.component';
import { ProductoModel } from 'src/app/shared/models/producto/producto.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProductoService } from 'src/app/shared/services/producto/producto.service';
import { SucursalService } from 'src/app/shared/services/sucursal/sucursal.service';
import { NavService, Menu } from '../../../../services/nav.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];

  products: ProductoModel[] = [];

  temp: ProductoModel[] = [];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  // SC CATWEB-396 Se define variable para indicar el total de articulos retornados.
  public totalItems : number = 0; 

  private cant_caracter = this.configService.config.productos?.cant_min_caracter;
  private cant_search = this.configService.config.productos?.cant_product_search;

  private datos = {
    Pagina: '0',
    Cant_Registros: 8,
    Nombre: '',
    Id_Marca: -1,
    Codigo: '',
    Codigo_Fabricante: '',
    Con_Stock: 'S',
    Id_Familia: -1,
    Incluye_SubFamilia: 'S',
    Codigo_Nombre_Descripcion: '',
    Id_Cliente: this.authService.usuario.id_cliente,
    Orden: 1,
    id_marca_aplic: -1,
    solo_con_aplic: 'N',
    nro_pieza: '',
    aplicacion: '',
    Id_Sucursal:-1 // SC CATWEB-410 se agrega parametro para consultar por sucursal.
  };

  @ViewChild('quickView') QuickView: ProductViewComponent;

  // Atributo para almacenar si el usuario puede o no ver los precios
  public verPrecios = this.configService.config.productos.ver_precios == 0 || !this.authService.usuario.invitado;

  constructor(public navServices: NavService, 
    private productoService: ProductoService,
    private configService: ConfigService,
    private authService: AuthService,
    private modalService: NgbModal,
    private sucursalService:SucursalService) {
    this.navServices.items.subscribe(menuItems => this.items = menuItems);
  }

  ngOnInit() {
    // SC CATWEB-410 si esta habilitado el modo por sucursal en sinergial se debe obtener la sucursal para realizar la consulta de los productos
    if(this.authService.usuario.modo_por_sucursal){
      // this.sucursalService.currentDataSucSelect$.subscribe((resp) =>{
      //   this.datos.id_sucursal = resp.id_sucursal;
      // });
      this.datos.Id_Sucursal = parseInt(this.authService.usuario.ids_sucursales.split(',',1)[0]);
    }
  }

  searchToggle() {
    this.navServices.search = false;
    this.removeFix();
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.products = [];
    let items = [];
    term = term.trim().toLowerCase();
    this.datos.Codigo_Nombre_Descripcion = term;
    if (term.length >= this.cant_caracter!) {
      setTimeout(() => {
        this.onSearch();
      }, 500);
      
    }
    this.items.filter(menuItems => {
      if (!menuItems?.title) return false
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName('body')[0].classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    this.products = [];
    document.getElementsByTagName('body')[0].classList.remove('offcanvas');
  }

  // SC CATWEB-396 se setea el total de items con la cantidad total retornada de la busqueda.
  onSearch() {
    const susc = this.productoService
      .getProductos(this.datos)
      .subscribe((data) => {
        this.temp = [...data];
        this.products = data;
        if(data.length > 0){
          this.products = data;
          this.totalItems = this.products[0].cant_registros;
        }
        this.checkSearchResultEmpty(this.products);
      });
    susc.unsubscribe;
  }

}
